.dropdown {
  position: relative;
  width: auto;
}

.dropdown .panel {
  display: none;
  height: 150px;
  width: 100%;
  position: absolute;
  top: 37px;
  list-style: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  overflow-y: scroll;
  background-color: #ffffff;
  z-index: 20;
  padding-inline-start: 20px;

  & li {
    font-size: 0.775rem;
    line-height: 24px !important;
  }
}

body {
  .panel-overlay {
    display: block;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    top: 0;
  }
}

.dropdown.active {
  & .panel {
    height: 150px;
    display: block;
    animation: slidepanel 0.25s;
    @keyframes slidepanel {
      0% {
        height: 0px;
      }
      100% {
        height: 150px;
      }
    }
  }
}

.dropdown input {
  position: absolute;
  width: 100%;
}

.dropdown {
  & > .rc-time-picker-select-option {
    height: inherit;
    width: 0;
    position: absolute;
    right: 30px;
    cursor: pointer;

    &:after {
      position: absolute;
      content: '\e908';
      font-family: 'transvoice-icon';
      top: 12px;
      left: 2px;
    }
  }
}
