@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-status-filter {
  font: 500 16px/20px $PRIMARY_FONT;
  padding: 16px 16px 16px 0px;
  background-color: $BACKGROUND_COLOR;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;

  &-list {
    list-style: none;
  }

  &__title {
    font: 500 16px/20px $PRIMARY_FONT;
    margin-bottom: 15px;
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }
    &__sub {
      :hover {
        cursor: pointer;
      }
      font-size: 14px;
      line-height: 18px;
      color: #74737b;
    }
  }

  .sub {
    font: 400 14px $PRIMARY_FONT;
    line-height: 18px;
    color: #fa0960;

    cursor: pointer;
  }
}
