@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

@import './media-query.scss';

.tv-right-status {
  padding: 0 32px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $BACKGROUND_COLOR !important;

  .nav-container {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  .navbar-expand {
    margin-left: 25px;
    .navbar-nav {
      align-items: center;
      gap: 8px;
      margin-right: -8px;
    }
  }

  .nav-item {
    margin: 5px 0;
    padding: 8px 16px;
    color: $COLOR_NAVIGATION_ITEM;
    font-family: $PRIMARY_FONT;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;

    &:hover {
      color: $COLOR_NAVIGATION_ACTIVE_ITEM;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .nav-active {
    color: $COLOR_NAVIGATION_ACTIVE_ITEM;
    background-color: #e1e6ff;
  }

  .tv-left-pannel__logo {
    @supports (display: grid) {
      padding-top: unset !important;
    }
    @supports (display: -ms-grid) {
      padding-top: 10px;
    }
  }

  .menu-button {
    display: none;
  }

  .booking-button {
    margin-left: 0;
    margin-bottom: 0;
    line-height: 12px;
    font-family: 'Circular Std', 'Circular Std';
    font-size: 14px;
    background: $BRIGHT_PINK_COLOR;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 14px 24px;
    border-radius: 40px;
    width: 133px;

    i.fa {
      -webkit-text-stroke: 1px $PRIMARY_COLOR;
      margin-right: 13px;
    }
  }

  .icon-search {
    color: $BLACK_COLOR;
  }

  a:hover {
    text-decoration: none;
  }

  & .tk-left-drawer {
    top: 72px;
  }
}
.button-style {
  margin-top: 15px;
  margin-left: 10pxs;
}
.tv-bookDirect-button-nav {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  border-radius: 40px;
  font-size: 14px;
  padding: 8px 22px;
  height: auto;
  color: $PRIMARY_COLOR;
}

.mobile-app-info {
  z-index: 21;

  .tv-modal__holder {
    background-color: $WHITE_COLOR;
    margin: auto 20px;

    .header {
      font: 500 18px/20px $PRIMARY_FONT;
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: $BLACK_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      letter-spacing: 0.2px;
    }

    .content-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .download-button {
          text-decoration: none;
          background: white;
          max-height: 60px;

          .store-badge {
            max-width: 160px;
            height: 60px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
