@media (max-width: 768px) {
  .tv-right-status {
    padding: 0px 16px 0 16px !important;
    margin-bottom: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);

    .nav-container {
      gap: 0 !important;
    }

    .navigation {
      display: none;
    }

    .menu-button {
      display: inline-block !important;
      font-size: 24px;
      border: none;
      top: -3px;
      background: none;
    }

    .icon-search {
      color: $BLACK_COLOR;
    }

    .mobile-nav {
      margin-left: 0px !important;
      align-items: center;
      display: flex;
    }

    .tv-avatar {
      width: unset !important;
      margin-right: unset !important;
      display: inline-grid !important;
      right: 10px;
    }

    .menu-button {
      display: none;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.tv-filter-list {
  &__element .tv-avatar-list__menu {
    position: fixed !important;
    top: 60px !important;
    right: 8px;
  }
  &__user {
    &-name {
      display: none;
    }
  }
}

.tv-search {
  display: none;
}
