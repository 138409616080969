@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-contactperson {
  &-tabs {
    padding-left: 15px;

    &-tab-1 {
      border: 1px solid #e0e0e0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 9px 19px 6px;
    }

    &-tab-2 {
      border: 1px solid #e0e0e0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 9px 19px 6px;
    }
  }

  &__list-item {
    // cursor: pointer;
    margin: 10px 15px 10px 10px;
    padding: 18px 0;
    font-family: 'PT Serif', serif;
    z-index: 10;
    background: #d8d8d8;
    display: flex;
    justify-content: space-around;

    sales &-child {
      margin: 0 13px;
      box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2),
        0 10px 18px -12px rgba(58, 68, 131, 0.2);
    }
  }

  &__item {
    &-status {
      width: 21.6%;
      align-items: center;
      justify-content: center;
    }

    &-name {
      width: 27.6%;
    }

    // &-icon {
    //   padding: 15px 40px;

    //   .icon {
    //     &:before {
    //       color: black;
    //     }
    //   }
    // }

    &-email {
      width: 30%;
      margin-right: 10px;
    }

    &-contactnum {
      width: 12%;
    }

    &-editbtn {
      width: 16.7%;
    }

    // &-datetime {
    //   width: 27.6%;
    // }

    // &-type {
    //   width: 12%;
    //   margin-right: 10px;
    // }

    // &-more_button {
    //   outline: none;
    //   border: none;
    //   background: none;
    //   text-transform: uppercase;
    //   font-size: 14px;

    //   &:after {
    //     content: "\e931";
    //     font-family: "transvoice-icon";
    //     -webkit-font-smoothing: antialiased;
    //     text-rendering: optimizeLegibility;
    //     -moz-osx-font-smoothing: grayscale;
    //     font-feature-settings: "liga";
    //     color: #c5c5c5;
    //     margin-left: 4px;
    //     font-size: 10px;
    //     color: $BLACK_COLOR;
    //   }
    // }

    // &-menu {
    //   width: 7%;
    //   align-items: center;
    //   justify-content: center;

    //   &-button {
    //     &:before {
    //       @include tv-transvoice-font;
    //       font-size: 1.5rem;
    //     }
    //   }
    // }

    &-label {
      font-size: 0.975rem;
      color: $BLACK_COLOR;
      margin: 0 0 11px 0;
      font-family: $PRIMARY_FONT;
    }

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font-size: 13px;
      font-weight: 300;
      font-family: $PRIMARY_FONT;

      &-available-until {
        font-weight: bold;
        color: $SECONDARY_COLOR;
      }
    }

    &-edit {
      color: $PRIMARY_COLOR;
      font-size: 14px;
      font-weight: 300;
      font-family: $PRIMARY_FONT;
      text-align: right;
      cursor: pointer;
    }
  }
  &__add-button {
    font-family: 'Circular Std', sans-serif;
    font-size: 16px;
    background: $PRIMARY_COLOR;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 15px;
    margin-left: auto;
    border-radius: 4px;
  }
  &__wrapper {
    height: calc(100vh - 275px);
  }
  &__delete {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: $PRIMARY_HOVER_COLOR;
    cursor: pointer;
    & > i {
      margin-right: 10px;
    }
  }
  &__list-wrapper {
    overflow-y: scroll;
    height: calc(100vh - 270px);
  }
}

@media (max-width: 768px) {
  .tv-contactperson {
    &__list-item {
      display: block !important;
      padding-left: 25px;
    }

    &__item {
      &-status {
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      &-name {
        width: 100%;
        padding: unset !important;
      }

      &-email {
        width: 100%;

        margin-right: 10px;
      }

      &-contactnum {
        width: 100%;
      }

      &-editbtn {
        width: 100%;
      }

      &-label {
        font-size: 0.975rem;
        color: $BLACK_COLOR;
        margin: 0 0 7px 0;
        font-family: $PRIMARY_FONT;
      }

      &-text {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 14px;
        font-weight: 300;
        font-family: $PRIMARY_FONT;
        margin-bottom: 18px;

        &-available-until {
          font-weight: bold;
          color: $SECONDARY_COLOR;
        }
      }
    }
    &__wrapper {
      height: 50vh;
    }
    &__list-wrapper {
      overflow-y: scroll;
      height: calc(100vh - 320px);
    }
    &__edit-modal {
      margin-bottom: 100px;
      &-actions {
        position: fixed;
        bottom: 0;
        width: 100vw;
        background-color: #fff;
        left: 0;
      }
    }
    &-item--mobile {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}

.custom {
  cursor: auto !important;
}
