@import "../../../Share/styles/variables.scss";

.tv-message-service {
  &__modal {
    top: 0;
    left: 0;
    height: 100vh;
    position: absolute;
    z-index: 62;

    .tv-modal__holder {
      border-radius: 4px;
      width: 620px;
      max-width: 620px;
      max-height: unset;
      top: 40px;
      background-color: #fff;
      overflow-x: hidden;

      &.message-service-holder {
        width: 1000px;
        padding: 125px 30px 36px 30px;
        border-radius: 24px;
        max-width: unset;
        height: auto;
      }

      &.message-service-preview_holder {
        width: 760px;
        padding: 125px 30px 36px 30px;
        border-radius: 24px;
        max-width: unset;
        height: auto;
      }

      &__custom-header {
        position: absolute;
        width: 100%;
        height: 104px;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: $BRIGHT_PINK_COLOR;
        display: flex;
        align-items: center;
        gap: 36px;
        display: flex;
        justify-content: center;

        h2 {
          color: $WHITE-COLOR;
          font-weight: 500;
          font-size: 32px;
          line-height: 40px;
        }
      }

      .tv-modal__btn-close {
        top: 20px;
        right: 20px;
        &::before {
          color: $WHITE-COLOR;
        }
      }
    }
  }

  &__container {
    display: flex;
    .left-conatiner {
      width: 45%;
      padding-right: 40px;
      p {
        font: 450 16px/20px $PRIMARY_FONT;
      }
      h2 {
        font: 700 20px/25px $PRIMARY_FONT;
      }
      .message-service_alert {
        padding-top: 10px;
      }
    }
    .right-conatiner {
      width: 55%;
      .container-1 {
        display: flex;
      }
      .form-group {
        .tv-single-assign-form__control {
          padding: 14px 20px;
        }
      }
    }
  }

  &__preview_container {
    display: flex;
    flex-direction: column;
    .top_container {
      display: flex;
      justify-content: center;
      .preview__message-logo {
        position: absolute;
        margin-top: 40px;
        // margin-left: 62px;
        width: 75px;
        height: 120px;
        object-fit: cover;
      }
    }
    .detail-conatiner {
      padding: 20px 10px 10px;
      h2 {
        font: 700 24px/30px $PRIMARY_FONT;
      }
      &__wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 80px;
        padding-top: 10px;
        &-details {
          font: 450 16px/20px $PRIMARY_FONT;
          &-item {
            padding-bottom: 10px;
          }
        }

        &-heading {
          font: 700 16px/20px $PRIMARY_FONT;
        }

        &-message {
          font: 450 16px/20px $PRIMARY_FONT;
          padding-top: 10px;
        }
      }
    }
  }

  &__actions {
    display: flex;
    padding-inline: 25px;
    padding-top: 40px;
    justify-content: space-between;
    button {
      width: 250px;
      height: 48px;
    }
    .cancel-button {
      color: #3f3f3f;
      border: none;
      text-decoration: underline;
    }
    .close-button {
      border-radius: 100px;
    }
  }
}
