@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-cancelassignment {
  &__wrapper {
    flex: 1.4 1;
    justify-content: center;
    align-items: center;
    padding-top: 33px;
  }

  &__container {
    width: 100%;
    background-color: $WHITE_COLOR;
    form {
      width: 100%;
    }
  }

  &__heading {
    border-radius: 4px 4px 0 0;
    background-color: $SECONDARY_COLOR;
    height: 64px;
    align-items: center;

    &-text {
      color: $WHITE_COLOR;
      font: 500 rem-calc(24) / rem-calc(35) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      padding-left: 24px;
    }
  }

  &__warning-icon {
    justify-content: center;
    margin-top: 46px;
    img {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    padding: 25px 0 0 0;
    align-items: center;
    justify-content: center;
    font: 400 24px/20px $PRIMARY_FONT;

    &-text {
      color: $NAV_BAR_TEXT_COLOR;
      font: 400 16px/20px $PRIMARY_FONT;
      margin-top: 10px;
      text-align: center;
      // height: 40px;
      padding-inline: 60px;

      &.text-medium {
        font: 300 rem-calc(22) / rem-calc(40) $PRIMARY_FONT;
      }
    }
  }

  &__reasons {
    margin: 20px auto;
    text-align: left;
    label {
      font-family: $PRIMARY_FONT;
    }
    &-list {
      flex-wrap: wrap;

      &-item {
        color: $WHITE_COLOR;
        padding: 13px 37px;
        margin: 0 18px 16px 0;
      }
    }

    &-list &-list-item {
      border: 1px solid $SECONDARY_COLOR;
      border-radius: 5px;
      font: 300 rem-calc(16) / rem-calc(23) $PRIMARY_FONT;
      &.tv-toggle-button--unselected {
        color: $SECONDARY_COLOR;
      }
    }

    &-title {
      color: $NAV_BAR_TEXT_COLOR;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    }

    &.no-reasons {
      margin: 19px 40.73px 40.73px 40.73px;
      .tv-cancelassignment__reasons-title {
        text-align: center;
      }
    }
  }

  &__buttons {
    margin: 0 0 32px 0;
    justify-content: center;

    button {
      width: 160px;
    }

    .tv-buttons {
      &__element {
        &--no-border {
          color: $PRIMARY_COLOR;
          font: 400 16px/20px $PRIMARY_FONT;
          letter-spacing: -0.2px;
        }
      }
    }
  }

  &__modal {
    z-index: 56;
    .tv-modal__holder {
      border-radius: 4px;
      margin: 0 10px;
      max-width: 400px;
      max-height: unset;
      top: 63px;
      background-color: #fff;
      overflow-x: hidden;

      .icon-close::before {
        display: none;
      }
    }

    &-container {
      overflow: hidden;
      margin-top: 5px;
      border-bottom: 1px solid #e0e0e0;
      font-family: "Circular Std", sans-serif;
      text-align: left;
      margin-right: -10px;
      min-width: 350px;
      height: 30vh !important;
      min-height: 250px;
    }
    &-icon {
      font-size: rem-calc(48);
      margin-top: 32px;
      .icon-warning::before {
        color: $PRIMARY_COLOR;
      }
    }
    &-text {
      color: $PRIMARY_COLOR;
      font: 300 rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      text-align: center;
      padding: 0 68px;
    }

    &-assignment {
      border: 1px solid $TEXT_INACTIVE_COLOR;
      background-color: $DARK_GRAY_COLOR-1;
      margin: 16px 36px 45px;
      padding: 9px 16px;
      &-id {
        color: $SECONDARY_COLOR;
      }

      &-title {
        color: #353535;
      }

      &-id,
      &-title {
        font: 300 rem-calc(16) / rem-calc(24) $PRIMARY_FONT;
        text-align: center;
      }
    }

    &-buttons {
      margin: 0 0 32px 0;
      justify-content: space-between;
      margin: 0 36px 40px;

      button {
        width: 169px;
        padding: 13px 0px;
      }
    }
  }
  &__sms-conatiner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 20px;
    padding-inline: 10px 10px;
    padding-bottom: 25px;
    &__text {
      font: 500 14px/20px $PRIMARY_FONT;
      width: 230px;
      text-align: start;
    }
    .tv-toggleSwitch {
      .tv-toggleSwitch__wrapper {
        .Slider.isChecked {
          background-color: #34c759;
        }
      }
    }
  }

  &__phone-box {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-inline: 10px 10px;
    label {
      font: 500 16px/20px $PRIMARY_FONT;
    }
  }
}
