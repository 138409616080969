@import '../../styles/variables.scss';
@import '../../styles/mixin.scss';

.calendar-small {
  width: 320px;

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $COMMON_TEXT_COLOR;
    font-weight: 500;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rdrDayWeekend {
    .rdrInRange {
      &.not-weekend {
        background-color: $WHITE_COLOR;
      }
    }
  }

  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-radius: 0% !important;
    left: 9px;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdrDay {
    height: 40px;
  }

  .rdrDayDisabled {
    background-color: $WHITE_COLOR;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: $PRIMARY_COLOR;
    border-radius: 4px;

    left: 4px;
    top: 5px;
    z-index: 1;
  }

  .rdrDay:not(.rdrDayPassive) {
    .rdrStartEdge {
      & + .rdrDayNumber {
        background: linear-gradient(
          to right,
          #ffff 0%,
          #e1e6ff 100%,
          #e1e6ff 50%,
          #e1e6ff 100%
        );
        &.not-weekend {
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      & + span + .rdrDayNumber {
        background: linear-gradient(
          to right,
          #ffff 0%,
          #e1e6ff 100%,
          #e1e6ff 50%,
          #e1e6ff 100%
        );
        &.not-weekend {
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &.rdrEndEdge {
        & + .rdrDayNumber {
          background: transparent;
        }

        & + span + .rdrDayNumber {
          background: transparent;
        }
      }
    }

    .rdrEndEdge {
      & + .rdrDayNumber {
        background: linear-gradient(
          to right,
          #ffe1ea 0%,
          #ffff 100%,
          #ffe1fa 100%,
          #ffff 0%
        );
        &.not-weekend {
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      & + span + .rdrDayNumber {
        background: linear-gradient(
          to right,
          #ffe1ea 0%,
          #ffff 100%,
          #ffe1fa 100%,
          #ffff 0%
        );
        &.not-weekend {
          background: $WHITE_COLOR;
        }
        span {
          z-index: 10;
          color: white !important;
        }
      }

      &.rdrStartEdge {
        & + .rdrDayNumber {
          background: transparent;
        }

        & + span + .rdrDayNumber {
          background: transparent;
        }
      }
    }
  }
  .rdrDayToday .rdrDayNumber span {
    color: $PRIMARY_COLOR !important;

    &:after {
      background: none !important;
    }
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayNumber {
    font-size: 16px;
  }

  .rdrWeekDay {
    font-size: 14px;
    line-height: 24px;
    color: $NAV_BAR_TEXT_COLOR;
  }
  .rdrWeekDays {
    display: none;
    font-family: $PRIMARY_FONT;
  }
  .rdrMonthAndYearPickers {
    width: 60px;

    select {
      background: transparent;
    }

    .rdrYearPicker {
      select {
        padding: 10px 0px 10px 0px;
        height: 100% !important;
      }
    }
    .rdrMonthPicker {
      width: 100%;

      select {
        color: $NAV_BAR_TEXT_COLOR;
        background: $WHITE_COLOR;
        justify-content: center;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        font-family: $PRIMARY_FONT;
        width: 100%;
        text-transform: capitalize;
        text-align: right;
        height: 100% !important;
        margin-top: -1px;
        text-align-last: center;
        appearance: none !important;
        option {
          text-align: center;
        }
        &:before {
          display: none;
          content: '';
          font-family: 'transvoice-icon';
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
          margin: 0 30px 0 0;
          color: #bdbdbd;
          cursor: pointer;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .rdrCalendarWrapper {
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize !important;
    width: 100%;
  }

  .rdrNextPrevButton {
    background: none;

    &::before {
      content: '\e932';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      margin: 0 30px 0 0;
      color: #bdbdbd;
      cursor: pointer;
    }

    i {
      display: none;
    }

    &:hover {
      background: none;
    }
  }

  .rdrNextButton {
    background: none;

    &::before {
      content: '\e931';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      margin: 0 0 0 20px;
      color: #bdbdbd;
      cursor: pointer;
    }

    i {
      display: none;
    }

    &:hover {
      background: none;
    }
  }

  .rdrMonth {
    padding: 0;
    margin: 12px 0 20px 0;
    width: auto;
    .rdrWeekDays,
    .rdrDays {
      width: auto;
      padding: 5px 10px;
    }
  }
  .rdrDateRangePickerWrapper {
    // filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
    border-radius: 4px;
    margin-bottom: 12px;
    border: 1px solid #bbb4b4;
    width: 100%;
  }
  .rdrMonthAndYearWrapper {
    padding: 0;
    margin: 16px;
    border: 1px solid $BORDER_COLOR;
    border-radius: 4px;
    height: 48px;
    .rdrNextPrevButton {
      height: 48px;
      margin: 0;
      padding: 16px;
      width: 48px;
      &::before {
        color: #000;
      }
      &.rdrNextButton {
        margin-right: 15px;
      }
    }
  }

  .tv-calendar-time {
    background-color: $WHITE_COLOR;
    border-bottom: 1px solid $PURPLE_BLOOD_COLOR;

    display: flex;
    width: 100%;

    &__item {
      width: 50%;
      padding: 20px;

      &--title {
        color: #a9a9a9;
        font-size: 14px;
        line-height: 20px;

        &::before {
          padding-right: 10px;
          font-size: 16px;
        }
      }
      &-value {
        text-align: center;
        color: $SECONDARY_COLOR;
        @include font-size(16);
        font-weight: bold;
        line-height: 24px;
        margin: 5px 0;
        &.no-icon {
          text-align: left;
        }
      }
    }
  }
  .select-weekday {
    background-color: $WHITE_COLOR;
    border-bottom: 1px solid $PURPLE_BLOOD_COLOR;
    justify-content: center;
    padding-bottom: 20px;

    &__title {
      color: $NAV_BAR_TEXT_COLOR;
      font-size: 14px;
      line-height: 24px;
      padding-right: 10px;
    }
  }
  .rc-time-picker-input {
    border: none;
    color: $SECONDARY_COLOR;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }

  .rc-time-picker::after {
    color: $SECONDARY_COLOR;
    margin-top: 22px;
  }

  .rc-time-picker-panel-input {
    color: $SECONDARY_COLOR !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer;
  }

  .rc-time-picker .icon-arrow-expand {
    margin-top: 22px;
  }
}
