@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  0% {
      transform: scale(.9);
  }

  to {
      transform: scale(1);
  }
}

@keyframes slide-in {
  100% { 
    transform: translateX(0%);
   }
}
