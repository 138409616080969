.tv-workassignment__list {
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 10px;

  &__mobile-fixed-bottom {
    position: fixed;
    background-color: #fff;
    text-align: center;
    display: flex;

    left: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
  }

  &-empty {
    display: flex;
    margin-top: 100px;
    text-align: center;
    flex-direction: column;

    &-img {
      display: block;
      background: url('/images/search-empty -state.svg') no-repeat center;
      background-size: contain;
      width: 100px;
      height: 100px;
      margin: 10px auto;
    }

    p {
      font-family: Circular Std;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      width: 558px;
      margin: 13px auto 0;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.icon-sort {
  &:after {
    color: #c5c5c5;
    margin-left: 4px;
    font-size: 10px;
    color: #252525;
  }
}

@media (min-width: 768px) {
  .tv-workassignment__wrapper,
  .tv-workassignment-calendar__wrapper {
    & .tk-left-drawer {
      top: 100px;
      height: 800px;
      .tk-dialog__wrapper {
        box-shadow: none;
        .tk-dialog__wrapper--before {
          display: none;
        }
      }

      .tk-assignment-list-box__container,
      .tk-assignment-detail__container {
        height: 100%;
      }
    }
  }
  .tv-workassignment-calendar__wrapper {
    margin-top: 0;
    & .tk-left-drawer {
      top: 72px !important;
    }
  }
  .tv-workassignment__list-scrolltop {
    background-color: white;
    height: 40px;
    align-items: center;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: flex;
    padding: 8px;
    right: 100px;

    border-radius: 8px;
    text-transform: capitalize;
  }
  .bounce-4 {
    animation-name: bounce-4;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }
  @keyframes bounce-4 {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-15px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
  .tv-advanced-order__scroll-container {
    height: 60vh !important;

    & > div {
      overflow-x: hidden !important;
    }

    .tv-checkbox .custom-control-input ~ .custom-control-label {
      font: 400 16px/18px 'Circular Std', sans-serif !important;
      font-size: 16px !important;
      letter-spacing: 0px !important;
      text-transform: capitalize !important;
      background-color: #fff !important;
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .tk-left-drawer {
    position: absolute !important;
    top: 155px;
    .tk-dialog__wrapper {
      box-shadow: none;
      .tk-dialog__wrapper--before {
        display: none !important;
      }
    }
    .tv-drawer_buttons {
      top: 630px;
      height: 50px;

      .tv_dashboard__add-button {
        margin-bottom: 0;
      }
    }

    .tk-assignment-list-box__container,
    .tk-assignment-detail__container {
      min-height: 700px;
    }

    .tv-workassignment__modal {
      height: var(--vh) !important;
      min-height: 1220px;

      .tv-modal__holder {
        height: unset;
        min-height: 700px;
        max-height: 1024px;

        .tv-workassignment__modal-scroll-container {
          height: 700px !important;
        }
      }

      .tv-modal__overlay {
        height: var(--vh) !important;
        min-height: 1220px;
      }
    }
  }
}
