@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-submittedcancel {
  &__title {
    margin-top: 36px;
    font: 500 rem-calc(24)/rem-calc(32) $PRIMARY_FONT;
    letter-spacing: -0.2px;
  }

  &__data {
    margin-top: 16px;
    font: 300 rem-calc(24)/rem-calc(35) $PRIMARY_FONT;
  }

  &__number {
    color: $SECONDARY_COLOR;
  }

  &__content {
    color: $TEXT_TITLE_COLOR;
  }
  
}