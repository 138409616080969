@import '../../../Share//styles//variables.scss';

.dashboard__single-chart-container {
  .percentage-chart {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    padding: 4px;

    &-title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    &-table {
      display: grid;
      grid-template-columns: 1.8rem 1fr 3rem;
      column-gap: 12px;
      row-gap: 8px;

      font-size: 15px;
      font-weight: 450;
      color: #222222;

      .number,
      .value {
        text-align: right;
      }

      .value {
        color: $FONT_LIGHT_GRAY_COLOR;
        font-weight: 400;
      }
    }
  }
  .percentage-bar-chart {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    padding: 4px;

    &-title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    &-table {
      display: flex;
      flex-direction: column;
      gap: 16px;

      font-size: 15px;
      font-weight: 450;
      color: #222222;

      &-row {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .data-container {
          display: flex;
          align-items: center;
          gap: 8px;

          .bar {
            width: 90%;
            height: 6px;
            border-radius: 8px;

            &-fill {
              height: 100%;
              border-radius: 8px;
            }
          }
        }

        .value {
          color: $FONT_LIGHT_GRAY_COLOR;
          font-weight: 400;
        }
      }
    }
  }
}
