@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-not-found {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;

  &__image {
    height: 342px;
    width: 324px;
    background-image: url("/images/not-found.svg");
    background-size: cover;
    background-position-y: center;
    margin: auto;
  }
}

.tv-title-result {
  font: rem-calc(24)/rem-calc(32) $PRIMARY_FONT;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #353535;
  &__number {
    color: $PRIMARY_COLOR;
  }

  &__order {
    color: $SECONDARY_COLOR;
  }
}