@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-reset-password {
  &__wrapper {
    width: 400px;
    flex-flow: column;
    align-items: center;
  }

  &__title {
    font: 3rem/3.375rem $PRIMARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  &__description {
    padding: 10px 30px 10px 30px;
    font: 0.875rem/1.5rem $SECONDARY_FONT;
    text-align: center;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__form {
    &-wrapper {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0 10px 37px 0 rgba(0, 0, 0, 0.12);
      padding: 36px 30px 41px 30px;
      margin-top: 16px;
    }
    &-label {
      font: 1.25rem/1.8125rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }
    &-retype-password-label {
      margin-top: 15px;
    }
    &-password-input, &-retype-password-input {
      width: 100%;
      &.tx-input-text {
        @include font-size(20);
        &::placeholder {
          @include font-size(14);
        }
      }
    }
    &-submit {
      &.tv-buttons__element {
        width: 100%;
        margin-top: 26px;
        padding: 13px 0px 13px 0px;
        font-weight: 300;
        line-height: 1.4375rem;
      }
    }
  }
}