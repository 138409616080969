$prefixClass: rc-time-picker;

.#{$prefixClass} {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  &-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;

    &-icon:after {
      content: "x";
      font-size: 12px;
      font-style: normal;
      color: #aaa;
      display: inline-block;
      line-height: 1;
      height: 20px;
      width: 20px;
      transition: color 0.3s ease;
    }

    &-icon:hover:after {
      color: #666;
    }
  }
}

@import "./index/Picker";
@import "./index/Panel";
@import "./index/Header";
@import "./index/Select";