@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-single-assign-form {
  &__control {
    border: 1px solid $BORDER_COLOR;
    border-radius: 6px;
    width: 100%;
    font: 400 16px/20px $PRIMARY_FONT;
    outline: none;
    color: $NAV_BAR_TEXT_COLOR;
    margin-bottom: 8px;
    & span[role="listbox"] {
      border-bottom: none;
    }
  }
}

.type_wrapper {
  margin-left: 15px;
  width: 93%;
}

.label {
  font: 16px $PRIMARY_FONT;
  margin: 0;
  padding: 0;
}
.tv-workassignment__filter-date-range__content {
  .calendar-small .rdrDayToday {
    cursor: pointer;
  }
}

.message-service-title {
  font-size: 24px;
  margin-bottom: 28px;
}

.detail-title {
  font-size: 12px;
  font-weight: 700;
}

.note-wrapper {
  p {
    font-style: italic;
    font-size: 12px;
  }
}

.messageservice-cancel-text {
  font: 700 12px/15px $PRIMARY_FONT;
  display: flex;
  justify-content: center;
  background-color: #ff9b4e4d;
  padding-block: 15px;
  margin-top: 10px;
  border-radius: 2px;
}
