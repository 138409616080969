@import '../../styles//variables.scss';

.tv-date-range-picker {
  &-container {
    width: 100%;
    position: relative;

    &-picker {
      top: 74px;
      left: 12px;
      position: absolute;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
      border-radius: 8px;
      overflow: hidden;
    }

    &-picker.calendar-small {
      .rdrMonthAndYearWrapper {
        .rdrYearPicker,
        .rdrMonthPicker {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;

          select {
            width: 100%;
            appearance: none;
            background: transparent;
          }
        }

        .rdrYearPicker select {
          font-size: 16px;
        }

        .rdrNextPrevButton.rdrNextButton {
          margin: 0;
          padding-left: 24px;

          &::before {
            margin: 0;
          }
        }
      }

      .rdrDayEndPreview {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;
        background-color: #e1e7fe;
        z-index: 0;
      }
      .rdrDayStartPreview {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 0;
        background-color: #e1e7fe;
        z-index: 0;
      }

      .rdrDayInPreview {
        border: 0;
        background-color: #e1e7fe;
        z-index: 0;
      }

      .rdrDayStartPreview.rdrDayEndPreview {
        background-color: #f1f3f4;
        border: none;
        z-index: 0;
      }

      .rdrDayNumber {
        span {
          font-weight: 500;
          color: $COMMON_TEXT_COLOR;
        }
      }

      .rdrDay {
        &[data-class-name='before'] {
          .rdrDayInPreview {
            background-color: transparent;
          }

          .rdrDayStartPreview {
            background-color: #f1f3f4;
          }
        }
      }

      .rdrDayToday {
        cursor: pointer;

        .rdrDayNumber span {
          color: $PRIMARY_COLOR !important;
        }
      }

      .rdrDayEndOfMonth .rdrInRange,
      .rdrDayEndOfMonth .rdrStartEdge,
      .rdrDayEndOfWeek .rdrInRange,
      .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .rdrDayStartOfMonth .rdrInRange,
      .rdrDayStartOfMonth .rdrEndEdge,
      .rdrDayStartOfWeek .rdrInRange,
      .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .rdrDayEndOfMonth,
      .rdrDayEndOfWeek {
        .rdrInRange,
        .rdrStartEdge {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .rdrDayStartOfMonth,
      .rdrDayStartOfWeek {
        .rdrDayInPreview,
        .rdrDayEndPreview {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      .rdrDayEndOfMonth,
      .rdrDayEndOfWeek {
        .rdrDayInPreview,
        .rdrDayStartPreview {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .rdrDay:not(.rdrDayPassive) {
        .rdrEndEdge {
          & + .rdrDayNumber {
            background: #e1e7fe;
          }

          & + span + .rdrDayNumber {
            background: #e1e7fe;
          }
        }
        .rdrStartEdge {
          & + .rdrDayNumber {
            background: unset;
          }

          & + span + .rdrDayNumber {
            background: unset;
          }
        }

        .rdrStartEdge.rdrEndEdge {
          & + .rdrDayNumber {
            background: unset;
          }
        }
      }

      .rdrDayPassive {
        .rdrDayNumber span {
          color: #808080;
        }
      }
    }
  }
  &-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &-section {
      flex: 1;

      &-title {
        font-size: 14px;
      }

      &-date {
        outline: none;
        width: 100%;
        border: 1px solid transparent;
        text-align: left;
        background-color: $WHITE_COLOR;
        padding: 14px 16px;
        border-radius: 24px;
        box-shadow: 4px 4px 5px rgba(179, 181, 187, 0.05);
        color: #535252;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .placeholder {
          color: $FONT_LIGHT_GRAY_COLOR;
        }

        &:focus {
          border: 1px solid $PURPLE_BLUE_COLOR;
        }

        &.error {
          border: 1px solid $PINK_COLOR;
        }
      }

      &-icon {
        color: $FONT_LIGHT_GRAY_COLOR;
        cursor: pointer;
      }
    }
  }
}
