.tv-radialgauge {
  &-container {
    position: relative;
  }
  &-inner_detail {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    bottom: 10px;
    left: 0px;
    width: 100%;
  }
}

.dashboard__report {
  &-container {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .dashboard__filter-holder {
      .tv-date-range-picker {
        &-fields {
          &-section {
            &-date {
              @media screen and (min-width: 768px) and (max-width: 1440px) {
                font-size: 14px;
                padding: 10px 12px;
              }
            }
          }
        }
      }
    }
    .dashboard__single-chart-container {
      &.custom-topic-padding {
        padding-top: 10px;
      }
    }
  }

  &-holder {
    .dashboard__chart {
      &-container {
        @media screen and (max-width: 768px) {
          flex-direction: column;
          margin: 16px 0;
        }
      }
    }
  }
}
