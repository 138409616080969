.#{$prefixClass}-panel {
  &-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;

    // DEV NITECO
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 20px;

    &-wrap {
      box-sizing: border-box;
      position: relative;
      // padding: 6px;
      border-bottom: 1px solid #e9e9e9;

      // DEV NITECO CUSTOMIZE
      height: 52px;
      width: 100px;
      border-radius: 4px;
      background-color: #FFFFFF;
      font-family: "PT Serif";
      font-size: 14px;
      line-height: 20px;
      color: #313131;
      padding: 16px 8px 16px 20px;
      display: flex;

      &__arrow-icon {
        font-size: 10px;
        color: #313131;
        padding: 0px 7px 0px 7px;
        cursor: pointer;
        transform: rotate(180deg);
      }
    }

    &-invalid {
      border-color: red;
    }
  }
}