@import "../../styles/variables.scss";

.tv-date-range-picker{
  &__container{

  }
  &__dialog{
    position: absolute;
    background: $WHITE_COLOR;
    z-index: 5;
    box-shadow: 0 10px 18px -12px rgba(58,68,131,0.2);
    border-radius: 0 4px 4px 4px;
  }
}