@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-set-new-password {
  &__wrapper {
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px $BORDER_COLOR;
    border-radius: 4px;
    width: 502px;
    padding: 48px 54px 56px;
    min-height: 414px;
    margin: auto;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
  }

  &__description {
    padding: 10px 30px 0px 30px;
    font: 0.875rem/1.5rem $PRIMARY_FONT;
    text-align: center;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__form {
    &-wrapper {
      width: 100%;
    }
    &-label {
      font: 1.25rem/1.8125rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }
    &-retype-password-label {
      margin-top: 15px;
    }
    &-password-input,
    &-retype-password-input {
      width: 100%;
      font-family: $PRIMARY_FONT;
      &.tx-input-text {
        @include font-size(20);
        &::placeholder {
          @include font-size(14);
        }
      }
    }
    &-submit {
      &.tv-buttons__element {
        width: 100%;
        margin-top: 26px;
        padding: 13px 0px 13px 0px;
        font-weight: 300;
        background-color: $PRIMARY_COLOR !important;
        border: none;
        line-height: 1.4375rem;
      }
    }
  }
}
