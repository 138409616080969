@import '../../styles/variables.scss';
.tv-display-mobile {
  &-show {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .tv-display-mobile {
    &-grid {
      display: grid !important;
    }
    &-hide {
      display: none !important;
    }
    &-show {
      display: block !important;
    }
  }

  .tk-weekly-calendar {
    &__event {
      &-single {
        // padding: 10px !important;
        margin: 5px !important;

        &_container1 {
          display: flex;
          padding-inline: 10px;
          padding-top: 10px;

          .skill {
            width: 100%;
            font-weight: 100;
          }

          .status {
            width: 25%;
            text-align: center;
            font-size: 8px;
            background: white;
            border-radius: 13px;
            color: black;
            padding: 1px 8px 0px;
            margin-bottom: 5px;
            text-transform: uppercase;
          }
        }

        &_container2 {
          display: flex;
          flex-direction: row;
          padding-inline: 10px;
          padding-bottom: 10px;
          .fields {
            letter-spacing: 0.7px;
            align-items: baseline;
            width: 50%;
            font: 400 12px/14px $PRIMARY_FONT;
            i {
              font-size: 16px;
            }
          }
          .icon-hearing {
            &:before {
              display: flex;
              -webkit-mask-size: 14px;
              background-color: #ffffff;
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      font-size: 12px !important;

      & .tk-calendar__title {
        margin: 0px 5px;
      }
      & .tk-calendar__tabs-wrapper {
        position: relative;
        margin: 0px auto;
      }
    }
    &-events {
      border: none;
      border-radius: 10px;
      overflow-y: scroll;
      margin-bottom: 80px;
      & .tk-weekly-calendar__day-row {
        min-height: 90px;
        min-width: auto;
      }
    }

    &__day {
      &-row {
        border: none !important;

        span {
          font: 11px/14px 'Circular Std', sans-serif !important;
          color: $FONT_LIGHT_GRAY_COLOR !important;
        }
      }
    }

    &__item {
      &-add {
        min-height: auto;
      }

      &-number {
        color: $FONT_LIGHT_GRAY_COLOR;
        font: 11px/16px 'Circular Std', sans-serif;
        font-weight: 400;
        text-align: center;
        background-color: $BACKGROUND_COLOR;
        text-align: left;
        padding: 8px 5px;
      }

      &-text {
        font: 1rem/1.625rem 'Circular Std', sans-serif !important;
        color: $BLACK_COLOR !important;
        margin-right: 6px;
        text-transform: uppercase !important;
      }
    }
  }
}

.tk-weekly-calendar__day-row:nth-child(1) {
  & .tk-weekly-calendar__item-number {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
