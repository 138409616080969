@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css'; // theme css file

.tv_dashboard {
  &__add-button {
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    background: $PRIMARY_COLOR;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 14px 24px;
    margin-left: auto;
    border-radius: 4px;
    margin-bottom: 16px;
    i.fa {
      -webkit-text-stroke: 1px $PRIMARY_COLOR;
      margin-right: 13px;
    }
  }
  &__current-time {
    color: $FONT_LIGHT_GRAY_COLOR;
    margin: auto 0 16px auto;
    align-self: center;
    font-weight: 500;
    font-size: 20px;
  }
  &__buttons {
    // margin-top: 32px;
    margin-bottom: 20px;

    a:hover {
      text-decoration: none;
    }
  }

  &__button {
    &-create-new-assignment {
      &.tv-buttons__element {
        margin-right: 32px;
        padding: 20px 38px 20px 34px;
        font: 1.125rem/1.75rem $PRIMARY_FONT;
        font-weight: 300;
        cursor: pointer;
        letter-spacing: -0.2px;
        box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);

        &:before {
          font-family: 'transvoice-icon';
          margin-right: 18px;
        }
      }
    }

    &-create-new-assignment {
      &:before {
        content: '\e921';
        color: $WHITE_COLOR;
      }
    }
  }

  &__news {
    width: 100%;
    background: $WHITE_COLOR;
    padding: 30px 25px;
    margin-top: 30px;
    border-radius: 5px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);

    &.tv-right-news {
      padding: 13px 21px;

      .tv-right-news__title {
        color: $TEXT_TITLE_COLOR;
        font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
        font-weight: 500;
        letter-spacing: -0.2px;
        margin: 10px 0;
      }

      .tv-right-news__description {
        color: $NAV_BAR_TEXT_COLOR;
        font: rem-calc(16) / rem-calc(24) $SECONDARY_FONT;
        padding-bottom: 20px;
      }
    }

    .tv-right-news__footer {
      padding: 13px 0;
      border-top: 1px solid #e8ecef;

      &--avarta {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 15px;
      }

      &--name {
        color: $NAV_BAR_TEXT_COLOR;
        font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
        font-weight: 600;
      }

      &--position {
        color: $NAV_BAR_TEXT_COLOR;
        font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;

      &-heading {
        color: #7f7f7f;
        font-family: 'SF Compact Display';
        @include font-size(16);
        line-height: rem-calc(16);
      }

      &-title {
        padding-top: 5px;
        color: $TEXT_TITLE_COLOR;
        font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
        font-weight: 500;
        letter-spacing: -0.2px;
      }
    }

    &__content {
      img {
        width: 100%;
        height: auto;
        display: block;
      }

      &-item {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__main-panel {
    display: flex;

    .tv-interperet-now__location-wrapper {
      padding: 11px 27px 30px 27px;
    }

    .tv-toggle-button--phone {
      margin-right: 10px;
    }
    .tv-search-box {
      input {
        margin: 0px 0px 8px 0px;
        width: 100%;
        background-color: #f1f3f4;
      }
      i {
        top: 14px;
        left: 14px;
      }
    }
    .tv-status-filter {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__calendar-panel {
    width: 100%;

    &__information {
      padding-top: 30px;
      color: $PRIMARY_COLOR;
      @include font-size(16);
      line-height: rem-calc(24);
      font-weight: bold;

      .icon-check {
        background-color: $PRIMARY_COLOR;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        display: inline-block;
        margin-right: 13px;

        &::before {
          @include font-size(13);
          color: $WHITE_COLOR;
          padding: 4px;
        }
      }
    }
  }

  &__availability-panel {
    margin-right: 20px;
  }

  &__req {
    &-wrapper {
      width: 33.333333%;
      margin-left: 32px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      color: #353535;
      font: 1.5rem $PRIMARY_FONT;
      font-weight: 500;
      letter-spacing: -0.2px;
      margin: 0 0 10px 0;
    }

    &-view-all {
      color: $PRIMARY_COLOR;
      font: 1.125rem/1.75rem $PRIMARY_FONT;
      font-weight: 300;
      letter-spacing: -0.2px;
      border: 0;
      background: transparent;
      cursor: pointer;
      margin-top: 3px;

      &:hover {
        text-decoration: none;
      }
    }

    &-item {
      margin-top: 16px;
      padding: 13px 21px;
      border-radius: 4px;
      box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
      background-color: white;
      cursor: pointer;

      &-title {
        color: $NAV_BAR_TEXT_COLOR;
        font: 1.125rem/1.75rem Circular Std;
        font-weight: 300;
        letter-spacing: -0.2px;
        padding: 0 0 10px 0;
      }

      &-number {
        color: $NAV_BAR_TEXT_COLOR;
        font: 1.125rem/1.75rem Circular Std;
        font-weight: 300;
        letter-spacing: -0.2px;
      }

      &-time {
        i::before {
          color: $SECONDARY_COLOR;
        }

        color: $SECONDARY_COLOR;
        font: 0.875rem/1.5rem $SECONDARY_FONT;
        border-top: 1px solid #e8ecef;
        padding: 10px 0 0 0;

        &-period {
          color: $DARK_GRAY_COLOR;
          font: 0.875rem/1.5rem $SECONDARY_FONT;
          padding: 10px 0 0 0;
        }
      }

      &-child {
        margin: 0 13px;
        box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2),
          0 10px 18px -12px rgba(58, 68, 131, 0.2);
      }

      &-parent {
      }
    }
  }
}
.tv-drawer {
  &__title-mobile {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
    font: 1.125rem/1.75rem $PRIMARY_FONT;
  }
  &_buttons {
    bottom: 170px;
    left: 10px;
    display: flex;
    position: absolute;
    width: calc(100% - 40px);
    font: 1rem/1.5rem $PRIMARY_FONT;
    z-index: 999;
    button {
      width: 100%;
      max-height: 50px;
    }
    &.list_drawer {
      bottom: 10px;
      left: unset;
      display: flex;
    }
  }
}

.tv-interperet-now {
  .tv-modal__btn-close {
    font-size: 1rem;
    top: 24px;
    right: 24px;
  }

  &__wrapper {
    background-color: $WHITE_COLOR;
    width: 550px;
  }

  &__header-text {
    background-color: $SECONDARY_COLOR;
    border-radius: 4px 4px 0 0;
    padding: 17px 24px 17px 32px;

    color: $WHITE_COLOR;
    font: 500 rem-calc(24) / rem-calc(35) $PRIMARY_FONT;
    letter-spacing: -0.2px;
  }

  &__content-wrapper {
    font: rem-calc(16) / rem-calc(24) $SECONDARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__content-title {
    background-color: $TITLE_COLOR;
    @include font-size(14);
    padding: 15px 0px 15px 32px;
  }

  &__location-wrapper {
    background-color: $WHITE_COLOR;
    padding: 24px 32px;
  }

  &__location-phone {
    width: 231px;
  }

  &__location-onsite {
    width: 231px;
    margin-left: 24px;
  }

  &__postal-code-title {
    margin-top: 20px;
    font: 300 rem-calc(20) / rem-calc(29) $PRIMARY_FONT;
  }

  &__postal-code-input {
    width: 100%;
  }

  &__date-control-wrapper {
    padding: 24px 32px 50px 32px;
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__date-wrapper {
    flex: 1.6;
    margin-right: 40px;
  }

  &__date-icon {
    margin-right: 9px;
  }

  &__start-time-wrapper {
    flex: 1;
    margin-right: 40px;
  }

  &__start-time-icon {
    margin-right: 9px;
  }

  &__end-time-wrapper {
    flex: 1;
  }

  &__end-time-icon {
    margin-right: 9px;
  }

  &__submit-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
  }

  &__submit-btn {
    .tv-interperet-now__submit-wrapper & {
      padding: 13px 47px;
    }
  }
}

.confirm-avaibility {
  .tv-modal__holder {
    background-color: $WHITE_COLOR;

    .icon-close:before {
      color: $NAV_BAR_TEXT_COLOR;
    }
  }

  &__content {
    text-align: center;
    padding: rem-calc(33) rem-calc(80);

    i {
      @include font-size(46);

      &::before {
        color: $SECONDARY_COLOR;
      }
    }

    &-title {
      color: $NAV_BAR_TEXT_COLOR;
      @include font-size(14);
      line-height: rem-calc(24);
    }

    &-description {
      color: $PRIMARY_COLOR;
      @include font-size(16);
      line-height: rem-calc(24);
      font-weight: 600;
    }

    &-confirm {
      width: 214px;
      padding: 20px 0;
    }
  }
}

.tv-block-wrapper .submit-time,
.confirm-avaibility__content button {
  margin-top: 20px;
  padding: 20px 0;
}

.PreviewArea {
  box-shadow: 1px 1px 81px rgba(41, 60, 74, 0.18);
}

.rdrDefinedRangesWrapper {
  display: none;
}

.tv_assignment_detail {
  &__button {
    flex-grow: 1;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .tk-weekly__main.tk-monthly-calendar__main {
  padding-top: 70px;
}

.tv-search-order-bar {
  &__loader {
    position: absolute;
    color: #6478e6;
    top: 0;
    right: 0;
    display: flex;
    padding: 18px 10px;
  }
}

.popup-content {
  padding: 5px;
  color: #787878;
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgba(117, 114, 114, 0.2) !important;
  background-color: #fcf7f8;
  border: 1pxsolid rgba(0, 0, 0, 0.05);
}
