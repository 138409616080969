button:focus {
  outline: none;
}

.tk-calendar {
  &__main-title {
    font: 15px/19px $PRIMARY_FONT;
    color: $FONT_LIGHT_GRAY_COLOR;
    display: flex;
    flex-direction: column;
    letter-spacing: 0.2px;
    bottom: 10px;
    position: fixed;
  }

  &__banner-container {
    position: relative;
    display: inline-block;
    .banner {
      width: 320px;
      height: 209px;
      object-fit: cover;
      margin-bottom: 10px;
      border-radius: 2px;
    }
    .banner-text {
      position: absolute;
      top: 16px;
      left: 53px;
      color: black;
      width: 214px;
      font: 700 26px/26px $PRIMARY_FONT;
      height: 52px;
    }
    .banner_google-play-image {
      position: absolute;
      top: 162px;
      left: 27px;
      width: 108px;
      height: 32px;
    }
    .banner_apple-store-image {
      position: absolute;
      top: 167px;
      left: 196px;
      width: 96px;
      height: 32px;
    }
    .inside-image {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__wrapper {
    border-radius: 5px;
  }

  &__header {
    display: flex;
    font-size: 16px;
    background-color: $BACKGROUND_COLOR;
    z-index: 2;
  }

  &__title {
    display: flex;
    color: $SECONDARY_COLOR;

    font-family: $PRIMARY_FONT;
    margin: 0 0 16px 0;
    height: 48px;
    background: $WHITE_COLOR;
    border: 1px solid $BORDER_COLOR;
    box-sizing: border-box;
    border-radius: 24px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: 16px;
      min-width: 120px;
    }
  }

  &__btn {
    &-filter {
      display: none;
    }

    &-newbook {
      display: none;
    }
  }

  &__button {
    @extend .tv-buttons__normal;
    padding: 0;
    outline: none;
    cursor: pointer;
    min-height: 45px;

    &:before {
      @include tv-transvoice-font;
      color: #a5a4a4;
      cursor: pointer;
    }

    &--previous {
      &:before {
        content: "\e932";
        margin: 0 15px;
      }
    }

    &--next {
      &:before {
        content: "\e931";
        margin: 0 15px;
      }
    }
  }

  &__tabs {
    &-wrapper {
      margin: 0 0 16px 24px;
      padding: 0 24px;
      display: inline-flex;
      gap: 24px;
      width: 168px;
      background: $WHITE-COLOR;

      height: 48px;
      border: 1px solid $BORDER_COLOR;
      box-sizing: border-box;
      border-radius: 24px;
      overflow: hidden;

      > li {
        list-style: none;
        cursor: pointer;
        width: 50%;
      }
    }

    &-button {
      border: 0;
      padding: 0;
      cursor: pointer;
      font: 1rem/1.4375rem $PRIMARY_FONT;
      border-radius: 4px;
      background: $WHITE-COLOR;
      color: $TEXT_INACTIVE_COLOR;
      height: 100%;
      width: 100%;
      outline: none;
      margin: auto;

      &:hover {
        color: $SECONDARY_COLOR;
      }

      &.selected {
        pointer-events: none;
        cursor: pointer;
        background: $WHITE-COLOR;
        color: $SECONDARY_COLOR;
      }
    }
  }
}

.tk-dialog {
  &__wrapper {
    position: absolute;
    min-height: 150px;
    width: 387px;
    left: calc(100% + 10px);
    top: -2px;
    box-shadow: 0 0 18px 0 rgba(197, 197, 197, 0.65);
    border-radius: 5px;
    z-index: 2;
    padding: 23px 35px;
    background: $BACKGROUND_COLOR;
    cursor: default;

    &--before {
      right: 100%;
      top: 10px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: $WHITE_COLOR;
      border-width: 5px;
    }
  }

  &__btn-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4;
    &:before {
      content: "\e926";
      color: $BLACK_COLOR;
      font-size: 16px;
    }
  }
}

$AVAILABLE_COLOR: #6566b9;

.tv-general-customTable {
  width: 100%;
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 1px 5px;

  &__wrapper {
    position: relative;
    margin-top: 30px;
    min-height: 200px;
  }

  &__wrapper-scroll {
    max-height: 350px;
    overflow: auto;
    margin-top: 20px;
  }

  & > thead {
    & > th {
      margin: 0 10px;
      padding: 10px 10px;
    }

    & > tr {
      position: absolute;
      z-index: 16;
      top: -20px;
      font:
        0.875rem/1.75rem "Circular Std",
        sans-serif;
      font-weight: 300;
      letter-spacing: -0.2px;
      box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
      height: 50px;
      background-color: #ffffff;

      & th {
        margin: 0 10px;
        padding: 10px 10px;
      }
    }
  }

  & > tbody {
    margin-top: 70px;

    & > tr:nth-child(1) {
      height: 10px;
    }

    & > tr {
      font:
        0.725rem/1.75rem "Circular Std",
        sans-serif;
      font-weight: 100;
      letter-spacing: -0.2px;
      box-shadow: 0 10px 18px -12px #74767d;
      height: 50px;
      background-color: #ffffff;

      & > td:nth-child(1) {
        text-align: center;
      }
    }
  }

  &__badge {
    position: relative;
    width: 60px;
    border-radius: 5px;
    text-transform: uppercase;
    font:
      0.675rem "Circular Std",
      sans-serif;
    margin: 5px;
    text-align: center;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29);

    &.date-timeSlot {
      width: 150px;
      font-size: 0.775rem;
      margin: 5px 15px;
    }

    &.timeSlot {
      width: 80px;
      margin: 5px 15px;

      & .tv-customTable__badge-edit-icon {
        &.fa-pencil::before {
          content: "";
        }
      }
    }

    &-available {
      color: $AVAILABLE_COLOR;
    }

    &-unavailable {
      color: $PRIMARY_COLOR;
    }

    &.active {
      text-align: left;
      background-color: #fff;
    }

    &.inactive {
      text-align: left;
      color: #c2c2c5;
      background-color: #757576cd;
    }

    &.edit {
      min-height: 24px;
      width: 80px;
      cursor: pointer;
      text-align: right;

      &-icon-only {
        width: 180px;
        padding-top: 6px;
        box-shadow: 0px 0px 0px 0px #75767c4a;
      }

      &.active {
        background-color: #fff;

        & > div {
          left: 3px;
          top: 4px;
          position: absolute;

          &:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid $SECONDARY_COLOR;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: $SECONDARY_COLOR;
          }
        }
      }

      &.inactive {
        color: #000;
        background-color: #fff;

        & > div {
          left: 3px;
          top: 4px;
          position: absolute;

          &:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid $SECONDARY_COLOR;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: #fff;
          }
        }
      }
    }
  }

  &__defaultAvailability {
    & > tbody td {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    & .disabled-func {
      color: #fff !important;
      cursor: not-allowed;
      background-color: #757576cd;
    }

    & .tv-display-flex {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    & > thead {
      & tr {
        width: 100%;

        & th {
          margin-top: auto;
          margin-bottom: 10px;
        }

        & th:nth-child(1) {
          width: 40vw;
        }

        & th:nth-child(2) {
          width: 40vw;
        }

        & th:nth-child(3) {
          width: 20vw;
        }
      }
    }

    & > tbody {
      & td:nth-child(1) {
        padding-left: 10px;
        width: 40vw;
      }

      & td:nth-child(2) {
        padding-left: 10px;
        width: 40vw;
      }

      & td:nth-child(3) {
        width: 20vw;

        & > div {
          justify-content: space-around;
        }
      }
    }
  }

  &__button {
    width: 30px;
    border-radius: 6px;
    font:
      0.875rem "Circular Std",
      sans-serif;
    line-height: 0.4rem;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #322878;
    color: #fff;
    padding: 6px 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29);

    & .disabled-func {
      color: black !important;
      cursor: not-allowed;
      background-color: #75767c4a;
    }

    &-submit {
      width: 80px;
      padding: 10px 5px;
      background-color: #322878;
    }

    &-cancel {
      width: 80px;
      padding: 10px 5px;
      color: #322878;
      background-color: #fff;
    }

    &-flow {
      width: 20px;
      box-shadow: 0px 0px 0px 0px #75767c4a;
      padding: 10px 5px;
      background-color: $SECONDARY_COLOR;
    }
  }

  &__default-nodata {
    width: 100%;
    font:
      0.875rem/1.75rem "Circular Std",
      sans-serif;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    height: 50px;
    background-color: #ffffff;
  }
}

.tk-preference {
  &-title {
    background: transparent;
    font:
      500 1.5rem/2rem "Circular Std",
      sans-serif;
    letter-spacing: -0.2px;
    color: #353535;
    margin: 15px auto 15px auto;
    width: 80%;
  }

  &__submit {
    position: fixed;
    bottom: 3px;
    right: 12%;
    background: transparent;
  }

  &__wrapper {
    width: 80%;

    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    margin: 0px auto 0 auto;
    min-height: 65vh;

    & label {
      font:
        0.875rem/1rem "PT Serif",
        serif;
      cursor: pointer;
    }

    input[type="checkbox"] {
      visibility: hidden;
    }

    input[type="checkbox"] + label:before {
      border: 1px solid $SECONDARY_COLOR;
      content: "\00a0";
      display: inline-block;
      font: 700 14px/1em sans-serif;
      height: 16px;
      margin: 0 0.5em 0 0;
      padding: 0;
      vertical-align: top;
      width: 16px;
    }

    input[type="checkbox"]:checked + label:before {
      background: $SECONDARY_COLOR;
      color: #fff;
      content: "\2713";
      text-align: center;
    }

    input[type="checkbox"]:checked:disabled + label:before {
      background: rgb(185, 179, 179);
      color: rgb(163, 163, 163);
      content: "\2713";
      text-align: center;
    }

    input[type="checkbox"]:disabled + label:before {
      background: rgb(185, 179, 179);
      color: rgb(185, 179, 179);
      content: "\2713";
      text-align: center;
    }

    input[type="checkbox"]:checked + label:after {
      font-weight: bold;
    }

    input[type="checkbox"]:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }

  &-subtitle {
    background: transparent;
    font:
      500 0.975rem "Circular Std",
      sans-serif;
    letter-spacing: -0.2px;
    color: #353535;
    margin: 15px 0px 15px 0px;
  }

  & .fixed_collapse {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // mobile safari
    max-height: 90vh;
  }

  &__container-scroll {
    height: 65vh !important;
    width: 100% !important;
    border-top: 1px solid #f1f1f1;

    & > div:nth-child(1) {
      height: 65vh;
      overflow-x: hidden !important;
    }

    & > div:nth-child(2) {
      & > div {
        display: none !important;
      }

      height: 50vh;
    }

    & > div:nth-child(3) {
      overflow: hidden !important;
    }
  }

  &__contract-group {
    width: 50%;

    & > div {
      width: 250px;
      // border: 1px solid #868686;
      border-radius: 10px;
    }
  }
}

.tk-left-drawer {
  position: absolute;
  top: 120px;
  left: 10px;
  min-height: 650px;
  max-height: 1000px;
  min-width: 320px;
  padding-top: 30px;
  transform: translateX(-100%);
  animation: slide-in 0.5s forwards;
  background-color: $BACKGROUND_COLOR;
  z-index: 50;

  &__close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;

    &::before {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .tk-dialog__wrapper {
    box-shadow: none;
    .tk-dialog__wrapper--before {
      display: none !important;
    }
  }
  .tk-dialog__body {
    height: 100%;
  }
}
.tk-assignment-detail {
  &__container {
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 625px !important ;
    border: 1px solid $BORDER_COLOR;
    background-color: $SIDE_DRAWER_BACK_COLOR;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px;

    .tk-dialog__btn-close {
      position: absolute;
      right: 0;
      font-family: $PRIMARY_FONT;
      top: 0;
      padding: 18px !important;
      height: 56px;
      z-index: 4;
      position: absolute;
    }

    & .icon-close::before {
      font-size: 0.8rem !important;
      font-weight: 700 !important;
    }
  }
  &__drawer {
    z-index: 5;
  }
}
.tk-assignment-list-box {
  &__container {
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 550px !important ;
    border: 1px solid $BORDER_COLOR;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px;

    .tk-dialog__btn-close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 18px !important;
      height: 56px;
      z-index: 4;
    }

    & .icon-close::before {
      font-size: 0.8rem !important;
      font-weight: 700 !important;
    }
  }

  &_header {
    font: 18px/23px $PRIMARY_FONT;
    padding: 10px 10px 10px 16px;
    letter-spacing: 0.2px;
    font-weight: 500;
    text-transform: capitalize;
  }

  &_drawer {
    height: 74vh;
  }

  &_body {
    border-top: 1px solid $BORDER_COLOR;
    padding: 10px;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;

    &-text {
      padding: 5px;
      color: #252525;
      font: 14px/18px $PRIMARY_FONT;
    }

    &-scroll {
      height: calc(100% - 80px) !important;
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: -30px;
    left: 0;
    padding: 0 12px;
  }
  &__event {
    &-item {
      font: 0.6875rem/1rem $SECONDARY_FONT;
      position: absolute;
      background: aliceblue;
      padding: 2px 5px 2px 5px;
      width: calc(100% + 2px);
      left: -1px;
      cursor: pointer;
      border-radius: 3px;
      line-height: 12px;
      color: $PRIMARY_COLOR;

      &--available {
        color: $COLOR_STATUS_AVAILABLE;
        border: 1px solid $COLOR_STATUS_AVAILABLE;
      }

      &--accepted {
        color: $COLOR_STATUS_ACCEPTED;
        border: 1px solid $COLOR_STATUS_ACCEPTED;
      }

      &--rejected {
        color: $COLOR_STATUS_REJECTED;
        border: 1px solid $COLOR_STATUS_REJECTED;
      }

      &--losted {
        color: $COLOR_STATUS_LOSTED;
        border: 1px solid $COLOR_STATUS_LOSTED;
      }

      &--traveling,
      &--working {
        color: $COLOR_STATUS_WORKING;
        border: 1px solid $COLOR_STATUS_WORKING;
      }

      &--fullfilled {
        color: $WHITE-COLOR;
        border: 1px solid $COLOR_STATUS_FULLILLED;
      }

      &--done {
        color: $COLOR_STATUS_DONE;
        border: 1px solid $COLOR_STATUS_DONE;
      }

      &--cancelled {
        color: $COLOR_STATUS_CANCELLED;
        border: 1px solid $COLOR_STATUS_CANCELLED;
      }

      &--submitted {
        color: $COLOR_STATUS_SUBMITTED;
        border: 1px solid $COLOR_STATUS_SUBMITTED;
      }

      // &--availableRequest {
      //   border: 1px solid $PRIMARY_COLOR;
      //   border-style: dashed;
      // }
      // &--transvoice {
      //   color: $PURPLE_BLUE_COLOR;
      //   border: 1px solid $PURPLE_BLUE_COLOR;
      // }

      &--custom {
        border: 1px solid $COLOR_STATUS_CUSTOM;
        color: $COLOR_STATUS_CUSTOM;
      }
    }

    &-accepted {
      background-color: #50c427 !important;
    }

    &-working {
      background-color: #6aa5fe !important;
    }

    &-fullfilled {
      background-color: #b4b4b4 !important;
    }

    &-submitted {
      background-color: #fa0960 !important;
    }

    &-cancelled {
      background-color: #fa0960 !important;
    }

    &-available {
      background-color: #2b7af1 !important;
    }

    &-single {
      color: $FONT_LIGHT_GRAY_COLOR;
      background-color: $WHITE-COLOR;
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
      border-radius: 4px;
      text-align: left;
      cursor: pointer;
      margin: 8px;
      padding: 9px;
      &_container1 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        font-family: $PRIMARY_FONT;
        margin-bottom: 5px;
        .header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #efefef;
          font: 400 14px/18px $PRIMARY_FONT;

          .notification {
            color: $BRIGHT_PINK_COLOR;
          }
        }
        .skill {
          margin-bottom: 8px;
        }
        .status {
          font-size: 8px;
          background: white;
          line-height: 20px;
          border-radius: 13px;
          color: black;
          padding: 1px 8px 0px;
          width: -moz-fit-content;
          width: fit-content;
          text-transform: uppercase;
        }
        @media (max-width: 1024px) {
          .skill {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &_container2 {
        display: flex;
        flex-wrap: wrap;

        font-family: $PRIMARY_FONT;
        .fields {
          font: 400 12px/14px $PRIMARY_FONT;
          margin: 0 auto 10px 0;
          display: flex;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          letter-spacing: 0.4px;
          align-items: flex-start;
          width: 50%;
          i {
            font-size: 16px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          .briefcase {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }

      .time:before {
        color: white;
        margin-right: 10px;
      }

      .contactPerson:before {
        color: white;
        margin-right: 10px;
      }
    }

    &-content {
      height: 100%;
      overflow: hidden;
      line-height: 1.4rem;
    }

    &-time {
      font-size: 0.5rem;
      font-weight: bold;
    }
  }
}

.stepper__item {
  flex: 100% 1;
  position: relative;
  height: 50px;
  background: $BORDER_COLOR;
  margin: 0 0 0 -12px;
  border-radius: 4px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  border: 1px solid $BORDER_COLOR;

  &.current {
    background: #fff;
    font-weight: bold;
  }

  & > div {
    z-index: 3;
    width: 100%;
    padding-right: 5px;
    font-size: 12px;
    font-family: $PRIMARY_FONT;
    position: absolute;
    top: 32%;
  }

  &:before {
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    background: $WHITE_COLOR;
    display: block;
    position: absolute;
    border-radius: 4px;
    top: 1px;
    left: 2px;
  }

  &.complete {
    background: $SECONDARY_COLOR;

    &::before {
      background: $SECONDARY_COLOR;
    }

    & > div {
      color: $WHITE_COLOR;
    }
  }

  &:first-child {
    clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  }

  &:last-child {
    clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);

    &::before {
      clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
}

@media (max-width: 768px) {
  .tk-calendar {
    &__mobile-fixed-bottom {
      position: fixed;
      background-color: $WHITE_COLOR;
      display: flex;
      left: 0;
      bottom: 0;
      width: 100vw;
      i.fa {
        -webkit-text-stroke: 1px $PRIMARY_COLOR;
      }
    }

    &__btn {
      &-newbook {
        font-family: $PRIMARY_FONT;
        display: flex;
        background-color: $PRIMARY_COLOR;
        color: $WHITE_COLOR;
        border: 1px solid $BORDER_COLOR;
        border-radius: 4px;
        max-height: 48px;
        justify-content: center;
        padding: 14px 10px;
        width: 50%;
        align-self: center;
        margin: 16px 16px 16px 0;
      }

      &-filter {
        font-family: $PRIMARY_FONT;
        justify-content: space-around;
        display: flex;
        font-size: 14px;
        background-color: #fff;
        border: 1px solid $BORDER_COLOR;
        border-radius: 4px;
        padding: 14px 0;
        align-self: center;
        width: 50%;
        margin: 16px;
      }
    }
    &__header {
      display: flex;
      justify-content: center;
      line-height: 16px;
      font-size: 12px !important;
      margin-bottom: 15px;
      & button.tk-calendar__tabs-button {
        font-size: 14px !important;
      }
      & .tk-calendar__title {
        margin: 0px;
        span {
          min-width: 90px;
        }
      }
      & .tk-calendar__tabs-wrapper {
        position: relative;
        margin: 0px 0px 0px auto;
      }
    }

    &__main-title {
      font-family: "Circular Std", sans-serif;
      font-size: 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: space-between;
    }
  }

  .tk-left-drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 50;
    &__close-icon {
      top: 15px;
    }
    .tv-drawer_buttons {
      width: calc(100vw - 20px);
      bottom: 30px;
      .tv_dashboard__add-button {
        margin-bottom: 0;
      }
    }
  }

  .tk-assignment-list-box,
  .tk-assignment-detail {
    &__container {
      height: 100vh !important;
      width: 100vw;
      border-radius: 0;
    }

    &_body {
      height: calc(100vh - 50px) !important;
      padding: 0px;
      border-top: none;
    }

    &_body-scroll {
      height: calc(100% - 130px) !important;
    }
  }
  .tv-advanced-order__scroll-container {
    height: calc(100vh - 125px) !important;
    & > div {
      overflow-x: hidden !important;
    }
    .tv-checkbox .custom-control-input ~ .custom-control-label {
      font:
        400 16px/18px "Circular Std",
        sans-serif !important;
      font-size: 16px !important;
      letter-spacing: 0px !important;
      text-transform: capitalize !important;
      background-color: #fff !important;
    }
  }

  .tv-workassignment__modal {
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    position: fixed;
    height: 100vh;

    .tv-modal__overlay {
      top: 0 !important;
      left: 0 !important;
    }

    &-scroll-container,
    &-container {
      min-width: 100% !important;
      height: auto;
      overflow-y: scroll;
      margin-bottom: 150px;
    }

    &-detail-container {
      min-width: 100% !important;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    &-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0 10px;
      width: 100vw;
      background-color: #fff;
    }

    .tv-modal__holder {
      width: calc(100vw);
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      margin-top: 0;
      top: 0;
      border-radius: 0;
    }

    .tv-workassignment__modal-body {
      height: calc(100vh - 140px); //fallback value
      height: calc(100svh - 140px);
    }

    .tv-workassignment__modal-status {
      & > div:nth-child(1) {
        text-align: left;
        width: 55%;
      }
    }
  }
  .tv-single-assign-form__preview-property {
    .property-value.edit-field {
      padding: 5px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@media (max-width: 1024px) {
  .tv-right-status {
    padding: 0 32px 0 24px !important;
  }
  .filter-border {
    border-radius: 4px;
    border: 1px solid $BORDER_COLOR;
    padding: 16px;
  }

  .tv-display-mobile {
    &-hide.tabs {
      display: none !important;
    }
    &-show.tabs {
      display: flex;
    }
  }
}
