@import '../styles/variables.scss';
@import '../styles/mixin.scss';
.tv-single-assign-form {
  &__hr {
    border-top: 1px solid $TEXT_INACTIVE_COLOR;
  }
  &__title {
    color: #353535;
    font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: center;
  }
  &__container {
    background: $WHITE_COLOR;

    &--order {
      margin: 0 auto 24px auto;
      .tv-single-assign-form__control {
        color: #313131;
      }

      .tv-single-assign-form__panel {
        padding-bottom: 30px;
      }
    }

    .tv-row {
      margin-right: -12px;
      margin-left: -12px;
      & > [class^='tv-col-'] {
        padding-right: 12px;
        padding-left: 12px;
      }
    }

    .tv-form__error {
      margin: 4px 0 3px;
      min-height: 1px;
      display: block;
    }
    .tv-form__control--error {
      margin-bottom: 0;
      border-color: red;
    }
  }
  &__action {
    &-container {
      margin: 0 auto 32px auto;
      justify-content: flex-end;

      button {
        box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
        margin-left: 24px;
        font: 1rem $PRIMARY_FONT;
        padding: 13px 16px 12px;
        font-weight: 300;
      }
    }
  }
  &__panel {
    padding: 10px 48px;
    &--secondary {
      background: #f8f8f9;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      padding: 0px 48px 20px;
    }
  }
  &__sub-title {
    color: $SECONDARY_COLOR;
    font: rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
    font-weight: 300;
    letter-spacing: -0.2px;
    margin: 24px 0 12px 0;
  }
  &__label,
  &__input {
    color: $NAV_BAR_TEXT_COLOR;
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    line-height: 24px;

    .tv-date-range-picker__container {
      border: 1px solid $BORDER_COLOR;
      border-radius: 4px;
      display: block;
      width: 100%;
      .tv-form__date-field {
        font: rem-calc(14) $SECONDARY_FONT;
        padding: 16px 20px;
        color: #a9a9a9;
        position: relative;

        &:after {
          font: 1rem;
          position: absolute;
          content: '\e929';
          font-family: 'transvoice-icon';
          top: 19px;
          right: 20px;
        }
      }
    }
  }
  &__label {
    margin: 16px 0 5px 0;
  }
  &__input {
    position: relative;
    &-arrow {
      &:after {
        content: '\e933';
        position: absolute;
        font: 0.7rem 'transvoice-icon';
        right: 27px;
        top: 20px;
        color: $NAV_BAR_TEXT_COLOR;
      }
    }
    &-sameabove {
      justify-content: flex-end;
      align-self: center;
      margin: 15px 0 0 0;

      .tv-toggleSwitch__wrapper {
        margin-left: 10px;
      }
    }
    &--my-home {
      margin-top: 15px;
    }
  }
  &__control {
    border: 1px solid $TEXT_INACTIVE_COLOR;
    border-radius: 4px;
    width: 100%;
    font: rem-calc(14) $SECONDARY_FONT;
    outline: none;
    color: $DARK_GRAY_COLOR;
    margin-bottom: 8px;
  }
  &__dropdownlist {
    padding: 14px 20px;
    appearance: none;
  }
  &__textfield {
    padding: 14px 20px;

    &-disabled {
      background-color: #f8f8f9;
    }
  }
  &__textarea {
    height: 120px;
  }
  &__more-placeholder {
  }
  &__file-zone {
  }
  &__control {
  }
  &__file {
    &-container {
      margin: 0;
      padding: 0;
      flex-flow: row wrap;
    }
    &-element {
      list-style: none;
      margin: 0 8px 0 0;
      width: calc((100% - 24px) / 4);
      &:nth-child(4n + 0) {
        margin-right: 0;
      }
    }
    &-name {
      border: 1px solid #eaeaea;
      border-radius: 0 0 5px 5px;
      background: #f8f8f8;
      font: rem-calc(10) $SECONDARY_FONT;
      color: #322878;
      bottom: 0;
      position: absolute;
      display: flex;
      width: 100%;
      padding: 10px 0;

      &:before {
        content: '\e93c';
        color: #322878;
        font: 0.75rem 'transvoice-icon';
        margin: 0 8px;
      }
    }
    &-item {
      min-height: 120px;
      border-radius: 5px;
      position: relative;
      justify-content: center;
      display: flex;
      &:before {
        color: $WHITE_COLOR;
        font: 2.6rem 'transvoice-icon';
        margin-top: 20px;
      }
      &--pdf {
        background: #ff9ba0;
        &:before {
          content: '\e93b';
        }
      }
      &--doc,
      &--docx,
      &--png,
      &--jpg {
        background: #6478e6;
        &:before {
          content: '\e93a';
        }
      }
      &--other {
        background: #6478e6;
        &:before {
          content: '';
        }
      }
      &--dragdrop {
        &:before {
          content: '\e93d';
          color: $PRIMARY_COLOR;
          font: 1.4rem 'transvoice-icon';
          margin-bottom: 4px;
        }
        background: $WHITE_COLOR;
        border: 1px dashed #cbcbcb;
        outline: none;
        font: rem-calc(12) $PRIMARY_FONT;
        font-weight: 300;
        text-transform: uppercase;
        color: #c5c5c5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 20px;
        height: 120px;
        cursor: pointer;
      }
    }
  }
  &__button {
    &-save {
      .tv-single-assign-form__action-container & {
        border-color: $WHITE_COLOR;
        background: $WHITE_COLOR;
      }
    }
    &-addSession {
      border: 1px dashed $PRIMARY_COLOR;
      border-radius: 5px;
      width: 100%;
      background: $WHITE_COLOR;
      cursor: pointer;
      color: $PRIMARY_COLOR;
      font: rem-calc(16) / rem-calc(24) $SECONDARY_FONT;
      font-weight: bold;
      text-align: center;
      outline: none;
      padding: 8px 0 10px 0;
      margin-top: 16px;
    }
    &-removeSession {
      background: $PRIMARY_COLOR;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 0;
      display: flex;
      align-self: center;
      justify-content: center;
      cursor: pointer;
      outline: none;

      &:before {
        content: '\e926';
        color: #fff;
        font-family: 'transvoice-icon';
      }
    }
    &-removeFile {
      color: $PRIMARY_COLOR;
      border: 0;
      background: transparent;
      display: flex;
      outline: none;
      font: rem-calc(14) $SECONDARY_FONT;
      cursor: pointer;
      margin: 10px 0;

      &:before {
        background: $PRIMARY_COLOR;
        content: '\e926';
        color: #fff;
        font: 0.45rem 'transvoice-icon';
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 4px;
        display: inline-block;
        margin: 3px 7px 0 0;
      }
    }
  }
  &__preview {
    &-text {
      font: bold rem-calc(16) / rem-calc(24) $SECONDARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }

    &-last {
      margin-bottom: 24px;
    }
  }
  &__back {
    &-corner {
      position: absolute;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $PRIMARY_COLOR;

      &::before {
        content: '\e934';
        @include tv-transvoice-font;
        font-size: 20px;
        padding: 0 5px 0 0;
      }
    }
  }
}

.tv-multiple-assign-form {
  &__title {
    color: #353535;
    font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: center;
  }
  &__wrapper {
  }
  &__panel {
    &-left {
      margin-right: 37px;
    }
    &-right {
    }
    &-tab {
      width: 323px;
      border-radius: 5px;
      background: $WHITE_COLOR;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;
      padding: 20px 24px;
      margin-bottom: 18px;
      border: 1px solid $WHITE_COLOR;

      &--error {
        border: 1px solid $PRIMARY_COLOR;
      }

      &--selected {
        border: 1px solid $SECONDARY_COLOR;
        &:after,
        &:before {
          left: 100%;
          top: 32px;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-left-color: $WHITE_COLOR;
          border-width: 6px;
        }
        &:before {
          border-left-color: $SECONDARY_COLOR;
          border-width: 8px;
          top: 30px;
        }

        &.tv-multiple-assign-form__panel-tab--error {
          border: 1px solid $PRIMARY_COLOR;
          &:before {
            border-left-color: $PRIMARY_COLOR;
          }
        }
      }
    }
    &-title {
      color: $NAV_BAR_TEXT_COLOR;
      font: rem-calc(18) $PRIMARY_FONT;
      font-weight: 300;
      letter-spacing: -0.2px;
      border-bottom: 1px solid #e8ecef;
      margin: 0 0 10px 0;
      padding: 0 0 10px 0;
    }
  }
  &__attributes {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 5px 0;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      &.level {
        &::before {
          content: '\e927';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }
      &.category {
        &::before {
          content: '\e92c';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }
      &.datetime {
        &::before {
          content: '\e929';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }
    }
  }
  &__button {
    &-add-more {
      border: 1px dashed $PRIMARY_COLOR;
      box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
      border-radius: 5px;
      color: $PRIMARY_COLOR;
      background: $WHITE_COLOR;
      font: rem-calc(16) $SECONDARY_FONT;
      font-weight: bold;
      width: 100%;
      margin-top: 6px;
      padding: 10px 0;
      cursor: pointer;
    }
    &-delete {
      color: $SECONDARY_COLOR;
      font: rem-calc(16) $SECONDARY_FONT;
      font-weight: bold;
      background: transparent;
      border: 0;
      position: absolute;
      right: 19px;
      top: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:before {
        content: '\e926';
        color: #322878;
        font: 0.5rem 'transvoice-icon';
        height: 8px;
        width: 8px;
        display: inline-block;
        margin: 0 7px 0 0;
      }
    }
  }
  &__back {
    &-corner {
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $PRIMARY_COLOR;

      &::before {
        content: '\e934';
        @include tv-transvoice-font;
        font-size: 20px;
        padding: 0 5px 0 0;
      }
    }
  }
}

.tv-form {
  &__checkbox {
    &-container {
      position: relative;
      display: flex;
      align-items: center;

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked ~ .tv-form__checkbox-checkmark {
          background-color: $PRIMARY_COLOR;
          border-color: #fff;
          &:after {
            content: '\e939';
            font: 7px 'transvoice-icon';
            color: #fff;
            position: absolute;
            top: 4px;
            left: 3px;
          }
        }
      }

      &--small-text {
        font-size: rem-calc(12);
      }
    }
    &-circular-container {
      font-size: 14px !important;
      display: flex;
      align-items: inherit;
      margin: 0px 8px 8px 8px;
      & .tv-form__checkbox-checkmark {
        border-radius: 10px !important;
        margin-right: 8px;
      }
    }
    &-checkmark {
      top: 0;
      left: 0;
      height: 17px;
      width: 17px;
      margin-right: 10px;
      position: relative;
      border-radius: 2px;
      border: 1px solid $BORDER_COLOR;
      cursor: pointer;
      padding-right: 15px;
    }
  }
  &__control {
  }
  &__error,
  &__warning {
    font: 12px $PRIMARY_FONT;
    font-weight: 300;
    color: red;
    display: inline-block;
    min-width: 20px;
    // height: 20px;
    line-height: 19px;
  }
  &__warning {
    color: #6478e6;
    margin-top: 8px;
  }
  &__date-field {
    background-color: $WHITE-COLOR;
    &:after {
      content: '\e929';
      font-family: 'transvoice-icon';
    }
  }
}
