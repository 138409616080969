@import "../../../Share/styles/variables.scss";

.tv-ondemand {
  &__modal {
    top: 0;
    left: 0;
    height: 100vh;
    position: absolute;
    z-index: 62;

    .tv-modal__holder {
      border-radius: 4px;
      width: 620px;
      max-width: 620px;
      max-height: unset;
      top: 63px;
      background-color: #fff;
      overflow-x: hidden;

      &.ondemand-holder {
        width: 750px;
        padding: 125px 50px 36px 100px;
        border-radius: 24px;
        max-width: unset;
        height: 665px;
      }

      &__custom-header {
        position: absolute;
        width: 100%;
        height: 104px;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: $BRIGHT_PINK_COLOR;
        display: flex;
        align-items: center;
        gap: 36px;
        padding-left: 100px;

        h2 {
          color: $WHITE-COLOR;
          font-weight: 500;
          font-size: 32px;
          line-height: 40px;
        }
      }

      .tv-modal__btn-close {
        top: 20px;
        right: 20px;
        &::before {
          color: $WHITE-COLOR;
        }
      }
    }
  }

  &__container {
    // padding-left: 50px;
  }

  &__top-container {
    display: flex;
    gap: 20px;

    img {
      height: 100px;
      width: 75px;
      object-fit: cover;
    }

    &__title {
      font: 700 24px/30px $PRIMARY_FONT;
    }

    &__message {
      font: 450 16px/20px $PRIMARY_FONT;
      padding-top: 10px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: auto auto;
    padding-top: 30px;
    gap: 20px;
    &-item {
    }
    &-language {
      font: 700 14px/18px $PRIMARY_FONT;
    }
    &-tel {
      display: flex;
      padding-top: 5px;
      font: 450 14px/18px $PRIMARY_FONT;
      &__number {
        color: #637dec;
      }
    }
  }
  &__actions {
    display: flex;
    padding-inline: 50px;
    padding-top: 40px;
    justify-content: space-between;

    &-print {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &__icon {
        padding-right: 8px;
        letter-spacing: 0.5px;
        font-size: 14px !important;
        color: #6478e6;
        cursor: pointer;
        &:hover {
          text-decoration: underline 2px;
        }
      }
    }
    button {
      width: 256px;
      height: 48px;
      border-radius: 100px;
      background-color: #fa0a5f;
    }
  }
}
