@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin scale-and-crop {
  -webkit-background-size: cover; /* For WebKit*/
  -moz-background-size: cover; /* Mozilla*/
  -o-background-size: cover; /* Opera*/
  background-size: cover; /* Generic*/
}

@mixin styleFont($weight, $size, $lineHeight, $color) {
  font-weight: #{$weight};
  font-size: pixel($size);
  line-height: pixel($lineHeight);
  color: #{$color};
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin dsiplayFlex() {
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + px;
  font-size: rem-calc($sizeValue);
}

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin tv-transvoice-font {
  font-family: 'transvoice-icon';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

@mixin one-line-ellipsis($width: auto, $max-width: 100%) {
  display: block;
  width: $width;
  max-width: $max-width;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin scroll-bar($max-heigh: null) {
  @if $max-heigh {
    max-height: $max-heigh;
  }
  overflow-y: scroll;
}
@mixin status-colors() {
  &--available {
    color: $COLOR_STATUS_AVAILABLE !important;
  }
  &--accepted {
    color: $COLOR_STATUS_ACCEPTED !important;
  }
  &--working {
    color: $COLOR_STATUS_WORKING !important;
  }
  &--fullfilled {
    color: $COLOR_STATUS_FULLILLED !important;
  }
  &--submitted {
    color: $COLOR_STATUS_SUBMITTED !important;
  }
  &--done {
    color: $COLOR_STATUS_DONE !important;
  }
  &--cancelled {
    color: $COLOR_STATUS_CANCELLED !important;
  }
  &--rejected {
    color: $COLOR_STATUS_REJECTED !important;
  }
  &--losted {
    color: $COLOR_STATUS_LOSTED !important;
  }
  &--newregistered {
    color: $COLOR_STATUS_NEWREGISTERED !important;
  }
  &--underInvestigation {
    color: $COLOR_STATUS_UNDERINVESTIGATE !important;
  }
  &--completed {
    color: $COLOR_STATUS_COMPLETED !important;
  }
}
