$paddingDirection:('right', 'left', 'top', 'bottom');
$paddingLength:(15, 30, 45, 50);

// if you only wants to use "padding" without postfix
@each $len in $paddingLength {
  .padding-#{$len} {
    padding: #{$len}px;
  }
}

// if you want to use padding-left, padding-right etc.
@each $dir in $paddingDirection {
  @each $len in $paddingLength {
    .padding-#{$dir}-#{$len} {
      padding-#{$dir}: #{$len}px;
    }
  }
}