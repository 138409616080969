@import '../../../Share/styles/variables.scss';

@import '../../styles/variables.scss';

.tk-monthly-calendar {
  &__scroll-container {
    & > div {
      & > div:nth-child(1) {
        padding-right: 15px;
      }
      & > div:nth-child(2) {
        overflow: hidden;
      }
    }
  }

  &__table {
    &-wrapper {
      font-family: $PRIMARY_FONT;
      width: 100%;
      @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
      border: 1px solid $BORDER_COLOR;
      box-sizing: border-box;
      border-radius: 8px;
      display: table;

      tr,
      tbody {
        @supports (display: grid) {
          display: grid;
          grid-column: 1 / -1;
          grid-template-columns: repeat(7, 1fr);
        }
        border-radius: 15px;
        width: 100%;
        color: $BLACK_COLOR;
        @supports (display: -ms-grid) {
          display: flex;
        }
      }

      td,
      th {
        border-right: 1px solid $BORDER_COLOR;
        border-bottom: 1px solid $BORDER_COLOR;
        background-color: transparent;
      }

      th {
        text-align: center;
        align-items: center;
        @supports (display: grid) {
          display: flex;
        }
        display: table-cell;
        padding: unset !important;
        justify-content: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        padding-top: 6px;
        height: 40px;
        text-align: center;
        text-transform: uppercase;
        color: $FONT_LIGHT_GRAY_COLOR;
      }

      td {
        height: 130px;
        vertical-align: top;
        padding: 8px 10px;
        position: relative;
        @supports (display: grid) {
          flex-direction: column;
          display: flex;
        }
        display: table-cell;
        min-height: 120px;

        &.highlight-day {
          border-radius: 4px;
          border: 2px solid $SECONDARY_COLOR;
        }
      }
    }

    &-weekdays {
      th {
        height: 40px;
        padding: 10px 15px;
        border-top: 0px #fff;
        border-left: 0px #fff;
        @supports not (display: grid) {
          display: table-cell !important;
        }
      }
      th:nth-last-child(1) {
        border-right: 0px #fff;
      }
    }

    &-item {
      .tk-monthly-calendar__table-wrapper.interpreter & {
        cursor: pointer;
      }

      .tk-monthly-calendar__item-el:hover & {
        background-color: white;
      }
      &-week-number {
        color: $FONT_LIGHT_GRAY_COLOR;
        font: 400 11px/14px $PRIMARY_FONT;
        position: absolute;
      }
    }

    &-item:hover,
    &-item:active {
      .tk-monthly-calendar__table-wrapper.interpreter & {
        background-color: rgba(176, 176, 240, 0.75) !important;
      }
      .tk-monthly-calendar__item-create-order {
        display: flex;
        width: auto;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s;
      }
    }
  }

  &__item {
    &_scrollable {
      height: 150px;
      overflow: scroll;
      overflow-x: auto;
    }

    &-number {
      color: $COMMON_TEXT_COLOR;
      font: 400 11px/14px $PRIMARY_FONT;
      letter-spacing: 0.2px;
      text-align: right;

      .today & {
        position: relative;
        color: $WHITE_COLOR;
        z-index: 2;
        &::before {
          position: absolute;
          top: -5px;
          right: -4px;
          content: '';
          background: $PRIMARY_COLOR;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          z-index: -1;
        }
      }
      .other-month & {
        color: $TEXT_INACTIVE_COLOR;
      }
    }

    &-text {
      display: none;
    }
    &__header {
      display: flex;
      background-color: #f1f3f4;
      padding: 8px;
      margin-top: 5px;
      cursor: pointer;
      justify-content: space-between;
      color: $COMMON_TEXT_COLOR;
      font: 14px/18px $PRIMARY_FONT;
      border-radius: 4px;
      .highlight-pill & {
        background-color: $SECONDARY_COLOR;
        color: $WHITE_COLOR;
      }
    }
    &-count {
      display: flex;
      align-items: center;
      text-align: center;
    }
    &-notification {
      i {
        color: #fa0960;
      }
    }
    &-create-order {
      width: unset;
      opacity: 0;
      background-color: #f1f3f4;
      color: $COMMON_TEXT_COLOR;
      font: 14px/18px $PRIMARY_FONT;
      border-radius: 4px;
      padding: 8px;
      margin-top: 8px;
      text-align: center;
      cursor: pointer;

      // &:hover {
      //   color: $SECONDARY_COLOR;
      //   text-decoration: underline $SECONDARY_COLOR 2px;
      // }
    }
    &-empty {
      font: 0.875rem $PRIMARY_FONT;
      display: none;
      margin-top: 5px;
      opacity: 0.7;
      color: $TEXT_INACTIVE_COLOR;
      text-align: center;
      padding: 10px 10px 0 10px;
    }

    &-more-btn {
      border: 0;
      background: #ff9ba0;
      font: 0.625rem/0.8125rem $SECONDARY_FONT;
      text-transform: uppercase;
      color: $WHITE_COLOR;
      border-radius: 10px;
      padding: 0 8px;
      cursor: pointer;
      outline: none;
    }

    &-view-all-btn {
      border: 0;
      font: 0.625rem/0.8125rem 'PT Serif', serif;
      text-transform: uppercase;
      border-radius: 10px;
      padding: 0 9px;
      margin-bottom: 10px;
      outline: none;
      background: #ff9ba0;
      color: white;

      &:after {
        margin-left: 3px;
        content: '\e931';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-feature-settings: 'liga';
        margin-left: 4px;
        font-size: 8px;
      }
    }

    &-availability-btn {
      border: 0;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      top: 9%;
      right: 14.5%;
      font: 0px $SECONDARY_FONT;
      background: #6566b9;
      height: 8px;
      width: 8px;
    }

    &-availability-icon {
      border: 0;
      border-radius: 50%;
      background: #6566b9;
      height: 8px;
      width: 8px;
      margin: 5px;
    }

    &-unavailability-icon {
      margin: 5px;
      border: 0;
      border-radius: 50%;
      background: $PRIMARY_COLOR;
      height: 8px;
      width: 8px;
    }

    &-unavailability-btn {
      border: 0;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      top: 9%;
      right: 2%;
      font: 0px $SECONDARY_FONT;
      background: $PRIMARY_COLOR;
      width: 8px;
      height: 8px;
    }

    &-el {
      font: 0.75rem/1.3rem $SECONDARY_FONT;
      padding: 3px 0 6px 10px;
      position: relative;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        border-radius: 100%;
        top: 9px;
        left: 0;
      }

      &--available {
        color: $COLOR_STATUS_AVAILABLE;

        &:before {
          background: $COLOR_STATUS_AVAILABLE;
        }
      }

      &--accepted {
        color: $COLOR_STATUS_ACCEPTED;

        &:before {
          background: $COLOR_STATUS_ACCEPTED;
        }
      }

      &--rejected {
        color: $COLOR_STATUS_REJECTED;

        &:before {
          background: $COLOR_STATUS_REJECTED;
        }
      }

      &--losted {
        color: $COLOR_STATUS_LOSTED;

        &:before {
          background: $COLOR_STATUS_LOSTED;
        }
      }

      &--traveling,
      &--working {
        color: $COLOR_STATUS_WORKING;

        &:before {
          background: $COLOR_STATUS_WORKING;
        }
      }

      &--fullfilled {
        color: $WHITE_COLOR;

        &:before {
          background: $COLOR_STATUS_FULLILLED;
        }
      }

      &--done {
        color: $COLOR_STATUS_DONE;

        &:before {
          background: $COLOR_STATUS_DONE;
        }
      }

      &--cancelled {
        color: $COLOR_STATUS_CANCELLED;

        &:before {
          background: $COLOR_STATUS_CANCELLED;
        }
      }

      &--submitted {
        color: $COLOR_STATUS_SUBMITTED;

        &:before {
          background: $COLOR_STATUS_SUBMITTED;
        }
      }

      &--custom {
        color: $COLOR_STATUS_CUSTOM;

        &:before {
          background: $COLOR_STATUS_CUSTOM;
        }
      }
    }

    &-el:hover {
      background-color: #e1cece;
      box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    }

    &-title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-row {
      .tk-monthly-calendar__item-list & {
        padding: 3px 10px 5px;

        &:hover {
          background: #ffd2cd;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }

    &-list {
      .tk-monthly-calendar__table-wrapper & {
        width: calc(100% + 70px);
        padding: 0;
        top: 82px;
        left: 77px;

        &:before {
          display: none;
        }
      }
    }

    &-list {
      .tk-monthly-calendar__table-wrapper & {
        width: calc(100% + 70px);
        padding: 0;
        top: 82px;
        left: 77px;

        &:before {
          display: none;
        }
      }
    }
  }

  &__list {
    &-date-month {
      margin-top: 5px;
      margin-bottom: 2px;
    }

    &-month {
      color: $NAV_BAR_TEXT_COLOR;
      font: 1rem/1.5rem $SECONDARY_FONT;
      margin: 5px 0 0 12px;
    }

    &-day {
      margin: -4px 0 10px 4px;
      color: $SECONDARY_COLOR;
      font: 1rem/1.4375rem $PRIMARY_FONT;
      font-weight: 500;
    }
  }

  &__button {
    text-align: center;
  }
}
