@import "../../../Share/styles/variables.scss";
@import "../../../Share/styles/mixin.scss";

.tv-assignment-type-selection-container {
  position: absolute;
  top: 56px;
  right: 0;
  transform: translateX(25%);
  width: max-content;
  max-width: 390px;
  max-height: fit-content;
  border-radius: 24px;
  background-color: $WHITE-COLOR;
  padding: 15px;
  box-shadow:
    -4px 0px 10px rgba(144, 144, 144, 0.15),
    4px 4px 10px rgba(145, 145, 145, 0.15);
  z-index: 100;

  display: flex;
  flex-direction: column;

  .selection-section {
    padding: 8px;
    font: 700 24px/20px $PRIMARY_FONT;
  }

  .selection-option {
    padding: 8px;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    &:hover {
      background-color: $BACKGROUND_COLOR;
    }

    .option-icon {
      min-width: 72px;
      height: 72px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 40px;
        max-height: 40px;
        width: auto;
        height: auto;
      }

      &.prebook {
        background-color: $PURPLE_BLOOD_COLOR;
      }
      &.directbook {
        background-color: $BRIGHT_PINK_COLOR;
      }
    }

    .option-text {
      .option-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }

      .option-description {
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .option-arrow {
      display: flex;
      align-items: center;
      color: $FONT_LIGHT_GRAY_COLOR;
    }
  }
}
