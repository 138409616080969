.tv-search-box {
  position: relative;
  input {
    background-color: #ffffff !important;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    height: 48px;
    font-size: 16px;
    font: 400 16px/18px 'Circular Std', sans-serif;
    margin: 0px;
    width: 100%;
    padding: 0px 12px 0 40px;
  }
  i {
    position: absolute;
    color: #e0e0e0;
    top: 14px;
    left: 16px;
  }
}
