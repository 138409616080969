@import '../../../Share/styles/variables.scss';

.mobile-wrapper {
  left: -200px;
}

.tv-mobile-navigation {
  background: #fff;
  padding: 14px 16px;
  display: none;
  width: 100%;
  z-index: 60;
  position: fixed;
  right: 0;
  height: 100vh;
  .tv-left-pannel__logo {
    padding-top: 5px;
    float: left;
    justify-content: end;
  }
  .nav-item-mobile {
    padding: 10px;
    text-align: center;
    display: block;
    font-weight: 600;

    color: $PRIMARY_COLOR;
    font-size: 17px;
    font-family: $PRIMARY_FONT;
    margin: 3px 20px;
  }

  .nav-active {
  }
}
.tv-bookDirect-button {
  border-radius: 40px;
  color: $PRIMARY_COLOR;
  margin: 0px 80px 25px 80px;
}
