@import "../../../Share/styles/variables.scss";

.frasbank_page {
  .title {
    color: $COMMON_TEXT_COLOR;
    margin-bottom: 10px;
    font: 500 40px/50px $PRIMARY_FONT;
  }

  .frasbank_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;
    // gap: 100px;

    .frasbank_subtitlte {
      color: #565656;
      margin-bottom: 20px;
      font: 450 24px/30px $PRIMARY_FONT;
    }

    .frasbank_link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      width: 370px;
      height: 48px;
      border-radius: 4px;
      background-color: #20175b;
      color: #ffffff;
      font: 500 16px/20px $PRIMARY_FONT;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        padding-left: 5px;
        padding-bottom: 5px;
      }
    }

    .frasbank_body {
      font: 450 16px/20px $PRIMARY_FONT;
      color: #252525;
    }

    .frasbank_content {
      width: calc(50% - 20px);
    }
    .frasbank_cover {
      width: 600px;
      height: 550px;
      background-color: #bcc5f8;
      position: relative;

      &-ellipse {
        padding-left: 79px;
        padding-top: 54px;
      }
      &-logo {
        position: absolute;
        top: 148px;
        left: 160px;
      }
    }
  }
}
