.tk-notification-detail {
  &__container {
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    height: 625px !important;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px;
  }
  &__drawer {
    z-index: 60;
  }
  @media screen and (max-width: 768px) {
    &__container {
      width: 100%;
      height: 100vh !important;
    }
  }
}

.tk-notification-item {
  &__container {
    display: flex;
    justify-content: space-between;
    height: 72px;
    padding: 18px 8px 18px 11px;
    border-bottom: 1px solid #ebebeb;

    .custom-control-label {
      padding: 0 !important;
      background: unset !important;
      color: unset !important;
      border-radius: 0 !important;
      text-transform: none !important;
    }
    .tv-control {
      margin: 6px 0px;
      position: relative;
      z-index: 1;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
      -webkit-print-color-adjust: exact;
    }
  }
  &__date {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #252525;
  }
  &__title {
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #808080;
  }
  &__label-new {
    background: #e94242;
    border-radius: 30px;
    color: #ffffff;
    font-size: 8px;
    height: 16px;
    width: 35px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 4px 8px;
    font-weight: 500;
    margin-top: 13px;
  }
}
