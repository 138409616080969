@import '../../../Share/styles/variables.scss';

.release-message-cont {
  position: absolute;

  min-height: 48px !important;
  min-width: 500px;
  max-width: 600px;
  left: 110px !important;
  justify-content: center;
  top: 12px;
  left: 0;

  &-alert {
    width: 48px;
    min-height: 48px;
    background: url('/images/bell.jpg') no-repeat center;
    display: flex !important;
    align-items: center;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    color: #ff9b4e;
  }
  &-close {
    width: 56px;
    min-height: 48px;
    margin-right: 10px;
    display: flex !important;
    align-items: center;
    font-size: 20px;
    -webkit-text-stroke: 1px #fff;
    justify-content: center;
    margin-left: 10px;
  }
  @media screen and (max-width: 768px) {
    top: 86px;
    left: 0 !important;
    z-index: 50;
    min-width: unset !important;
    max-width: unset !important;
    width: calc(100% - 15px);
    margin: 0 15px;
    &-alert {
    }
    &-close {
    }
    .body {
      .release-summary {
        display: block;
        font: 12px 'Circular Std', sans-serif;
        width: 100%;
        margin: 16px 0;
        text-overflow: unset !important;
        white-space: pre-line !important;
        overflow: visible !important;
      }
    }
  }

  h2 {
    margin-top: 8px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 6px;
    font-size: 31px;
    background-color: #aaa;
    border-radius: 100px;
    padding: 16px 8px 17px 7px;
    line-height: 0px;
    cursor: pointer;
  }

  .close:hover {
    background-color: #0ac130;
    color: #fff;
  }

  .body {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    margin: 0px;
    border: 1px solid $BORDER_COLOR;
    border-radius: 900px !important;
    min-width: 200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 3px 4px 11px 0px rgba(11, 12, 19, 0.149);
    transition: max-height 2s ease-out;
    padding: 0;
    .release-summary {
      display: block;
      font: 12px 'Circular Std', sans-serif;
      width: 100%;
      text-overflow: ellipsis;
      white-space: break-spaces;
      overflow-x: hidden;
    }

    .link-style {
      width: 100%;
      text-decoration-line: underline;
      color: $PRIMARY_COLOR;
      font: 12px 'Circular Std', sans-serif;
    }
    .release-actions {
      display: flex;
      justify-content: flex-end;
      padding-left: 15px;

      min-width: 80px;
    }

    .content {
      width: 100%;
      padding-right: 8px;

      ul {
        list-style-type: none;
        margin: 0px 0px 0px 20px;
        padding: 0px;

        li {
          display: none;

          .see-more {
            font-size: 13px;
            font-style: italic;
            color: #fff;
            cursor: pointer;
            margin-left: 5px;
            background-color: #bb6e6e;
            border-radius: 100px;

            padding: 2px 7px 2px 6px;
          }
        }

        li:nth-child(1) {
          display: list-item;
          font: 1rem 'Circular Std', sans-serif;
          .see-more {
            display: inline-block;
          }
        }

        li:nth-last-child() {
          .see-more {
            display: none;
          }
        }
      }

      &.expanded {
        ul {
          li {
            display: list-item;
          }

          li:nth-child(1) {
            .see-more {
              display: none;
            }
          }

          li:nth-last-child() {
            .see-more {
              display: inline-block;
            }
          }
        }
      }
    }

    .close-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        &.danger {
          background-color: red;
          color: #fff;
        }
      }
    }
  }

  .body::before {
    color: #827e7e !important;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.release-message-list-cont {
  height: auto;
  max-height: 688px;
  overflow-y: scroll;
  margin-top: 30px;

  ul {
    margin: 0 5px;
    list-style-type: none;
    padding: 0;
    color: #444;

    li {
      font-weight: 100;
      margin: 23px 0px;
      display: flex;
      flex-direction: column;
      border: solid 1px #e5e5e5;
      -webkit-box-shadow: 10px 10px 9px -14px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 10px 10px 9px -14px rgba(0, 0, 0, 0.75);
      box-shadow: 10px 10px 9px -14px rgba(0, 0, 0, 0.75);
      -moz-transition: height 0.5s ease-out;
      -ms-transition: height 0.5s ease-out;
      -o-transition: height 0.5s ease-out;
      -webkit-transition: height 0.5s ease-out;
      transition: height 0.5s ease-out;
      text-align: justify;

      &:nth-child(1) {
        margin: 0px;
      }

      .header {
        background-color: #f7f7f7;
        padding: 5px 11px 2px 2px;

        .btn-expan {
          float: left;
          padding: 4px 8px 0px 9px;
          cursor: pointer;
        }

        .date {
          font-style: italic;
          font-size: 12px;
          float: right;
          padding-top: 5px;
          font-weight: 500;
        }
      }

      p {
        margin: 5px 0;
        padding: 0 9px 5px 10px;
        line-height: 26px;

        &.hidden-items {
          display: none;
        }
      }

      &.expanded {
        p {
          &.hidden-items {
            display: block;
          }
        }
      }
    }
  }
}
.send-unrated-link {
  margin: 16px 0px;
  cursor: pointer;
  text-decoration: none !important;
  font: 16px/20px 'Circular Std', sans-serif;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  color: $COMMON_TEXT_COLOR !important;
  background-color: $WHITE_COLOR;
  border: 1px solid $BORDER_COLOR !important;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}
