@import '../../../../Share/styles/variables.scss';
@import '../../../../Share/styles/mixin.scss';

$MARGIN-LEFT-HEADER: 32px;

.tv-myprofile {
  &__wrapper {
  }

  &__field {
    margin: 10px 0;
    font: 500 16px/18px $PRIMARY_FONT;
  }

  &_value {
    font-weight: 100;
    margin-top: 15px;
  }

  &_hr {
    border-width: 1px;
    border-color: $BORDER_COLOR;
    margin: 40px 0px;
  }

  .tx-input-text--border {
    width: 100%;
  }

  .tv-row {
    margin-bottom: 32px;
  }

  &__header-wrapper {
    padding: 32px 0 0px 0;
    position: relative;
    align-items: center;
    display: flex;
  }
  &__header-text {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: #353535;
    font: 500 20px/25px $PRIMARY_FONT;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__client-number {
    margin-top: 10px;
    font: 500 16px/18px $PRIMARY_FONT;
    letter-spacing: 0.3px;
    margin-left: $MARGIN-LEFT-HEADER;
  }

  &__avatar {
  }

  &__avatar-img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  &__content-wrapper {
    padding: 38px 0 0px 0;
  }

  &__input-label {
    color: $NAV_BAR_TEXT_COLOR;
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    margin-bottom: 5px;
  }

  &__change-avatar-btn,
  &__edit-btn {
    margin-left: $MARGIN-LEFT-HEADER;
    margin-bottom: 60px;

    &.tv-buttons__element {
      font-weight: 300;
      padding: 13px 31px;
      line-height: 23px;
    }
  }

  &__change-avatar-btn {
    display: flex;
    width: 220px;
    justify-content: center;

    & + input[type='file'] {
      display: none;
    }

    &:before {
      content: '\e928';
      @include tv-transvoice-font;
      font-size: 20px;
      color: $SECONDARY_COLOR;
      margin-right: 16px;
    }
  }

  &__certification-wrapper {
    margin-bottom: 32px;
  }

  &__certification-header {
    font: 300 rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    letter-spacing: -0.2px;
    position: relative;
    padding-left: 33px;

    &::before {
      content: '\e927';
      @include tv-transvoice-font;
      color: $SECONDARY_COLOR;
      font-size: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__certification-data {
    font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
    letter-spacing: 0.3px;
  }

  &__submit-wrapper {
    justify-content: center;
  }

  &__submit-btn {
    margin-top: 50px;

    &.tv-buttons__element {
      font-weight: 300;
      padding: 13px 48px;
      line-height: 23px;
    }
  }
}
