.tv-tooltip{
  display:inherit;
  margin-left: 4px;
  
  i{
    font-size:15px;
  }
 
  
}
