@import '../../../Share/styles/variables.scss';

.tv-route-leaving-guard-modal {
  z-index: 56 !important;
  text-align: center;

  .tv-modal__holder {
    border-radius: 4px;
    margin: 0 10px;
    max-width: 400px;
    max-height: unset;
    background-color: $WHITE_COLOR;
    overflow-x: hidden;
  }


  &-area {

    &-title {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      font-family: $PRIMARY_FONT;
    }

    &-text {
      font-size: 14.6px;
      margin-bottom: 25px !important;
      width: 185px;
      margin: auto;
      font-family: $PRIMARY_FONT;
    }

    &-backBtn {
      background-color: $WHITE_COLOR;
      margin: 0 7px;
    }

    &-submitBtn {
      background-color: $PRIMARY_COLOR;
      margin: 0 7px;
    }

  }

  &__buttons {
    justify-content: space-between;
    margin: 0px 10px 15px;

    button {
      width: 120px;
      font-size: 14px;
      height: 45px;
    }
  }

}