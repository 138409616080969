@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
@import '../../../Share/styles/fonts.scss';

.feedBack {
  padding: 10px 34px;
  width: 800px;
  background-color: $WHITE_COLOR;
  margin: 0 auto 10px;

  &__modal {
    .tv-modal__holder {
      // max-width: initial;
      // padding: 0 15px !important;
      min-width: 672px;
    }
  }

  input:disabled {
    background: $WHITE_COLOR;
  }

  select:disabled {
    color: $DARK_GRAY_COLOR;
  }

  &__rating {
    min-height: 150px;
    background-color: $DARK_GRAY_COLOR-1;
    text-align: center;
    margin: 48px 0 36px;
    padding: 15px 32px 5px;

    &-text {
      color: $DARK_GRAY_COLOR;
      font-family: $SECONDARY_FONT;
      @include font-size(14);
      letter-spacing: 0.3px;
      line-height: rem-calc(20);
    }
  }

  .form-wrapper {
    padding: 0px;
  }

  .tv-single-assign-form__control {
    margin-bottom: 24px;
  }

  textarea.tv-single-assign-form__control {
    height: auto !important;
    padding: 16px !important;
    max-height: 120px !important;
    min-height: 48px;
  }

  .tv-single-assign-form__input-arrow {
    position: relative;
    margin-top: 5px;
  }

  .tv-single-assign-form__textfield {
    padding: 16px 20px;

    &:disabled {
      color: $DARK_GRAY_COLOR;
    }
  }

  .tv-single-assign-form__label {
    margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 400;

    &.icon-calendar {
      & > div {
        display: inline-block;
        padding-left: 10px;
      }
    }

    &.icon-submit-time {
      & > div {
        display: inline-block;
        padding-left: 10px;
      }
    }
  }

  .tv-single-assign-form__input {
    margin-left: 0;
  }
  &_modal {
    .tv-modal__holder {
      text-align: center;
      padding: 20px 90px 30px;

      .image-upload {
        & > input {
          display: none;
        }

        .icon-upload-to-cloud {
          &::before {
            color: $SECONDARY_COLOR;
            @include font-size(22);
          }
        }

        &__title {
          @include font-size(14);
          line-height: rem-calc(24);
          color: $SECONDARY_COLOR;
          margin: 5px;
        }
      }

      .image-preview {
        width: 120px;
        border: 1px solid #979797;
        border-radius: 2px;
        margin: 20px auto 0;
        position: relative;

        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }

        i {
          &.icon-close {
            cursor: pointer;
            position: absolute;
            top: -12px;
            right: -12px;
            background-color: $PRIMARY_COLOR;
            width: 24px;
            border-radius: 50%;
            height: 24px;

            &::before {
              color: white;
              @include font-size(12);
            }
          }
        }
      }

      .icon-upload-to-cloud {
        cursor: pointer;
        border: 1px solid $SECONDARY_COLOR;
        border-radius: 50%;
        display: inline-block;
        padding-top: 5px;
        margin-top: 20px;
        width: 40px;
        height: 40px;

        [class*='path']::before {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'transvoice-icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          @include font-size(24);
        }
      }

      .path1::before {
        color: $SECONDARY_COLOR;
      }
    }

    &__header {
      color: $NAV_BAR_TEXT_COLOR;
      font: rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
      font-weight: 300;
      letter-spacing: -0.2px;
    }
  }

  .input-group {
    min-width: 275px;
    padding: 28px 0 24px;

    &.view-data {
      padding: 0;
      min-width: 0;
    }

    &__label {
      margin-right: -1px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 8px 16px;
      margin-bottom: 0;
      font-size: 1rem;
      @include font-size(14);
      line-height: rem-calc(24);
      color: $NAV_BAR_TEXT_COLOR;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #e8e8e8;
      border-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__value {
      min-width: 100px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      padding: 8px 16px;
      color: $TEXT_INACTIVE_COLOR;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__textfield {
      border: 1px solid #e8e8e8;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      padding: 8px 16px;
      color: $PRIMARY_COLOR;
    }
  }
  @media (max-width: 768px) {
    &__modal .tv-modal__holder {
      width: calc(100vw);
      min-width: 100vw !important;
      height: 100vh;
      max-height: 100vh;
      top: 0;
      border-radius: 0;
      button.tv-rate-submit-btn {
        width: 100%;
      }
      &-content .detailed-rating {
        & > label:before {
          font-size: 1.5rem !important;
        }
      }
    }
  }

  &-header {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: $PRIMARY_FONT;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 20px;
    margin-top: 48px;
    color: $COMMON_TEXT_COLOR;
  }
  &-content {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: $PRIMARY_FONT;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    padding-top: 20px;
    color: #74737b;
  }
  .rating-row {
    justify-content: center;
  }
  .star-group {
    display: flex;
    margin: 40px 0 32px 0;
    justify-content: center;
    & .react-stars span {
      margin: 0 13px;
    }
  }

  .rating-label {
    margin: 5px 0px;
    font: 400 16px/20px 'Circular Std', sans-serif;
  }

  .redirect-checkbox {
    padding: 0px 5px;
  }

  .redirect-checkbox label {
    font: 400 16px/20px 'Circular Std', sans-serif;
  }

  .tv-single-assign-form__dropdownlist {
    padding: 10px 10px !important;
    background-image: linear-gradient(45deg, transparent 50%, #696767 50%),
      linear-gradient(135deg, #696767 50%, transparent 50%),
      linear-gradient(to right, white, white) !important;
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
    background-repeat: no-repeat !important;

    &:after {
      content: '\e933';
      position: absolute;
      font-family: 'transvoice-icon';
      top: 12px;
      left: 2px;
    }
  }

  .comment-text {
    font-style: italic;
    font-size: 0.875rem;
    color: #313131 !important;
    padding: 2px 6px;
    display: flex;
    flex-direction: row;
  }

  .label-height {
    height: 8px;
  }
  .field-wrapper {
    margin-top: 10px;
  }
  .tv-form__control--error {
    margin-bottom: 0;
    border: 2px solid red !important;
  }

  .feedback-create-deviation {
    color: $PRIMARY_COLOR;
    font-family: $PRIMARY_FONT;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
