@import '../../../../Share/styles/variables.scss';
@import '../../../../Share/styles/mixin.scss';

.tv-general-notification {
  &__container {
    padding: 22px 21px 16px;
    border-bottom: 1px solid #e0e0e0;
    font-family: $PRIMARY_FONT;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    display: flex;
    font-weight: bold;
    font-size: 14px;
    color: #252525;
    font-family: $PRIMARY_FONT;
  }
  &__new-label {
    background: #e94242;
    border-radius: 30px;
    color: #ffffff;
    font-size: 8px;
    height: 16px;
    width: 35px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 4px 8px;
    font-weight: 500;
    &-forpage {
      margin-right: 8px;
    }
  }
  &__message {
    font-size: 12px;
    color: #252525;
    margin-top: 11px;
    margin-bottom: 22px;
    font-family: $PRIMARY_FONT;

    &-forpage {
      margin-top: 24px;
      margin-bottom: 4px;
    }
  }
  &__date {
    font-size: 12px;
    color: #252525;
  }
  &__mark-as-read {
    margin-left: auto;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
    height: 0;
    font-family: $PRIMARY_FONT;
    .custom-control-input {
    }
    .tv-control {
      margin: 0 !important;
      bottom: 4px !important;
    }
    &-label {
      margin-right: 11px;
      font-size: 12px;
      color: #000000;
    }
    .custom-control-label {
      padding: 0 !important;
      background: unset !important;
      color: unset !important;
      border-radius: 0 !important;
      text-transform: none !important;
      &:before {
        border: 2px solid black;
      }
    }
  }
  &__buttons {
    margin-left: auto;
    font-size: 12px;
    display: flex;
    button {
      height: 24px;
      outline: none;
      border: none;
      width: 80px;
    }
    &-accept {
      background: $PRIMARY_COLOR;
      border-radius: 4px;
      color: white;
    }
    &-decline {
      background: #ffffff;
      border: 1px solid #e0e0e0 !important;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
  &__checkbox {
    position: relative;
    top: -32px;
    height: 0;
    .tv-checkbox {
      &-active {
        &:before {
          background-color: black !important;
        }
      }
      &-inactive {
        &:before {
          background-color: white !important;
        }
      }
    }
  }
}
