.icon-hearing:before {
  display: flex;
  // background-image: url('/images/hearing-icon.svg');
  // background-size: 16px 16px;

  -webkit-mask-image: url('/images/hearing-icon.svg');
  -webkit-mask-size: 16px;
  mask-size: 16px;
  mask-image: url('/images/hearing-icon.svg');
  background-color: #74737b;

  content: '';
  height: 16px;
  width: 16px;

  @media (max-width: 768px) {
    background-size: 22px 22px;
    height: 20px;
    width: 20px;
    -webkit-mask-size: 20px;
    mask-size: 20px;
    padding: 5px;
  }
}
