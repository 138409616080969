@import '../../Share/styles/variables.scss';

.tv-bg-login-page {
  background-color: $WHITE_COLOR;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
}

.tv-login-page {
  &__wrapper {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
  }

  &__form-container {
    display: -webkit-flex;
    display: flex;
    margin: auto;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
  }
  &__right {
    &-wrapper {
      background-color: $WHITE_COLOR;
      flex: 1.4;
      justify-content: center;
      align-items: center;

      .tab-panel-content {
        padding: 52px;
        @include scroll-bar(100vh);
      }
      @media screen and (max-width: 768px) {
        flex: none;
        margin-top: 25px;
      }
    }

    &-footer {
      &-wrapper {
        position: absolute;
        font-family: $PRIMARY_FONT;
        bottom: 0;
        margin: auto auto 26px auto;
        @media screen and (max-width: 768px) {
          position: relative;
          margin: 26px auto 26px auto;
        }
      }
      &-text {
        font: 0.875rem/1.5rem $PRIMARY_FONT;
        span {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

.tv-login {
  &__wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 502px;
    @media screen and (max-width: 768px) {
      box-shadow: none;
      border: none;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    margin-bottom: 40px;
  }

  &__description {
    padding: 10px 30px 10px 30px;
    font: 0.875rem/1.5rem $PRIMARY_FONT;
    text-align: center;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__form {
    &-wrapper {
      width: 100%;
      padding: 36px 56px 56px 56px;
      font-family: $PRIMARY_FONT;
      margin-top: 16px;
      .tv-form__control {
        border: 1px solid #e0e0e0;
        height: 48px;
        box-sizing: border-box;
        padding: 14px 16px;
        border-radius: 4px;
        margin: 8px 0;
        outline: none;
        font-weight: 400;
        background-color: #ffffff !important;
      }
      @media screen and (max-width: 768px) {
        margin: 16px;
        width: calc(100vw - 32px);
        padding: 0 16px;

        box-shadow: none;
      }
    }
    &-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #252525;
      margin-bottom: 8px;
    }
    &-email-input {
      width: 100%;
    }
    &-interpreter-id-label {
      margin-top: 15px;
    }
    &-interpreterId-input {
      width: 100%;
    }
    &-submit {
      &.tv-buttons__element {
        background: $PRIMARY_COLOR;
        border-color: $PRIMARY_COLOR;
        width: 100%;
        margin-top: 26px;
        padding: 13px 0px 13px 0px;
        font-weight: 300;
        line-height: 1.4375rem;
      }
    }
  }
}
